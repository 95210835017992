import Swal from 'sweetalert2';

type Props = {
  title: string;
  message: string;
};

const showSuccessAlert = ({ title, message }: Props) => {
  Swal.fire({
    title,
    text: message,
    icon: 'success',
    timer: 5000,
    showConfirmButton: false,
  });
};

export default showSuccessAlert;
