import {
  TBranch,
  TInvoice,
  TPesticideInMou,
  TReport,
  TSchedule,
  TUser,
} from '.';
import { TAsset } from './asset';

export enum EMouStatus {
  NotStarted = 'NotStarted',
  PendingDownPayment = 'PendingDownPayment',
  InProgress = 'InProgress',
  Delayed = 'Delayed',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
  InReview = 'InReview',
  FullyPaid = 'FullyPaid',
  Reopened = 'Reopened',
}

export type TMou = {
  id: string;
  code: string | null;
  branch: TBranch;
  customer: TUser;
  kpi: string;
  supervisor: TUser;
  description: string;
  fileUrl: string;
  status: EMouStatus;
  schedules: TSelectedDateInMou[];
  invoices: TInvoice[];
  branchId: string;
  customerId: string;
  supervisorId: string;
  price: number;
  pesticides: TPesticideInMou[];
  reports: TReport[];
  assets: TAsset[];
  technicians: TAssignedTechnician[];
  sopFileUrl: string | null;
  legalFileUrl: string | null;
  createdAt: string;
  updatedAt: string;
};

export type TAssignedTechnician = {
  id: string;
  mouId: string;
  userId: string;
  user: TUser;
  createdAt: string;
  updatedAt: string;
};

export type TSelectedDateInMou = {
  id: string;
  schedule: TSchedule;
  mouId: string;
  scheduleId: string;
  mou: TMou;
  createdAt: string;
  updatedAt: string;
};

export type TCreateProject = {
  code: TMou['code'];
  branchId: TMou['branchId'];
  customerId: TMou['customerId'];
  supervisorId: TMou['supervisorId'];
  description: TMou['description'];
  fileUrl: string | null;
  price: TMou['price'];
  technicians: { mouId: string; userId: string }[];
  sopFileUrl: TMou['sopFileUrl'];
  legalFileUrl: TMou['legalFileUrl'];
};

export type TSelectProject = {
  id: string;
  companyName: string;
  businessField: string;
  logoUrl: string | null;
};
