export enum DocumentationType {
  Photo = 'Photo',
  Document = 'Document',
}

export type TDocumentation = {
  id: string;
  operationId?: string;
  complainId?: string;
  responseId?: string;
  description: string | null;
  type: DocumentationType;
  photoUrl: string;
  createdAt: string;
  updatedAt: string;
};

export type TDocPreview = {
  file: File | null;
  url: string;
  type: DocumentationType;
  description: string;
};

export type TCreateDocumentation = {
  operationId?: TDocumentation['operationId'];
  complainId?: TDocumentation['complainId'];
  responseId?: TDocumentation['responseId'];
  photoUrl: TDocumentation['photoUrl'];
  type: DocumentationType;
  description?: string;
};
