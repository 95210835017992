import React from 'react';

const DashboardIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      data-v-26e5b7b0=""
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      aria-hidden="true"
      role="img"
      className={props.className ?? ''}
      width="1em"
      height="1em"
      viewBox="0 0 256 256"
    >
      <g fill="currentColor">
        <path
          d="M88 48v160H40a8 8 0 0 1-8-8V56a8 8 0 0 1 8-8Z"
          opacity=".2"
        ></path>
        <path d="M216 40H40a16 16 0 0 0-16 16v144a16 16 0 0 0 16 16h176a16 16 0 0 0 16-16V56a16 16 0 0 0-16-16ZM40 152h16a8 8 0 0 0 0-16H40v-16h16a8 8 0 0 0 0-16H40V88h16a8 8 0 0 0 0-16H40V56h40v144H40Zm176 48H96V56h120v144Z"></path>
      </g>
    </svg>
  );
};

export default DashboardIcon;
