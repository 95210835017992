import { logout, useAuth } from '@root/api/auth';
import { Outlet, useNavigate } from 'react-router-dom';
import MainLayout from './_layouts/MainLayout/MainLayout';
import { useEffect, useState } from 'react';
import { TUser } from '@core/types';
import LoadingScreen from './_shared/LoadingScreen';
import { jwtDecode } from 'jwt-decode';
import { getJwt, removeJwt } from '@core/utils/jwt';

const ProtectedRoute = (): JSX.Element => {
  const [userData, setUserData] = useState<TUser>();
  const { user, isAuthLoading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthLoading) {
      const jwt = getJwt();

      if (user) {
        if (jwt) {
          const decodedJwt: any = jwtDecode(jwt);

          if (decodedJwt.resetToken !== user.data.resetToken) {
            removeJwt();
            navigate('/login');
          }
        }

        setUserData(user.data);
      } else {
        navigate('/login');
      }
    }
  }, [user, isAuthLoading]);

  if (!isAuthLoading && userData) {
    return (
      <MainLayout>
        <Outlet />
      </MainLayout>
    );
  } else {
    return <LoadingScreen />;
  }
};

export default ProtectedRoute;
