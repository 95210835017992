import clsx from 'clsx';
import React from 'react';

interface Props {
  alt: string;
}

const ToolTip = ({ alt }: Props) => {
  return (
    <div
      className={clsx(
        'transition-all pointer-events-none opacity-0 group-hover:opacity-100 absolute bg-slate-900 rounded text-white -top-10 whitespace-nowrap py-1 px-2.5 text-sm -translate-y-2 group-hover:translate-y-0 font-normal z-[2]'
      )}
    >
      <span>{alt}</span>
      <div
        className={clsx(
          'block absolute -bottom-1 w-2 h-2 rotate-45 left-1/2 -translate-x-1/2 bg-slate-900'
        )}
      />
    </div>
  );
};

export default ToolTip;
