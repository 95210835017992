import { TLogin, TResponse, TUser } from '@core/types';
import { api, authFetcher } from '../core/utils/api';
import { getJwt, removeJwt, storeJwt } from '@core/utils/jwt';
import useSWR from 'swr';
import Swal from 'sweetalert2';

export const useCurrentUser = () => {
  const { data, isLoading, error, isValidating, mutate } = useSWR(
    '/auth/user-info',
    authFetcher
  );

  return {
    currentUser: data as TResponse<TUser>,
    isCurrentUserLoading: isLoading,
    currentUserError: error,
    isCurrentUserValidating: isValidating,
    mutateCurrentUser: mutate,
  };
};

export const getUserInfo = async () => {
  const { data: user }: { data: TUser } = await api.get('/auth/user-info', {
    headers: {
      Authorization: `Bearer ${getJwt()}`,
    },
  });

  return user;
};

export const login = async (body: TLogin) => {
  try {
    const { data } = await api.post('/auth/login', body);

    if (data) {
      storeJwt(data.accessToken);
    }

    const user = await getUserInfo();

    return {
      success: true,
      message: 'Logged in successfully!',
      statusCode: 200,
      data: user,
    };
  } catch (err: any) {
    const { message, statusCode, error } = err.response.data;

    return {
      success: false,
      message,
      statusCode,
      data: error,
    };
  }
};

export const logout = async () => {
  return Swal.fire({
    title: 'Apakah anda yakin ingin logout?',
    text: 'Anda akan melakukan logout',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: 'rgb(16,185,129)',
    cancelButtonColor: '#f43f5e',
    confirmButtonText: 'Logout',
    cancelButtonText: 'Batal',
  }).then(async (res) => {
    if (res.isConfirmed) {
      removeJwt();
      return {
        success: true,
        message: 'Anda berhasil logout!',
      };
    } else {
      return {
        success: false,
      };
    }
  });
};

export const useAuth = () => {
  const {
    data: user,
    error,
    isLoading,
    isValidating,
    mutate,
  } = useSWR('/auth/user-info', authFetcher);

  return {
    user: user as TResponse<TUser>,
    login,
    logout,
    authError: error,
    isAuthLoading: isLoading,
    isAuthValidating: isValidating,
    mutateAuth: mutate,
  };
};
