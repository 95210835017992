import ChevronUpIcon from '@components/_shared/Icons/ChevronUpIcon';
import { useLayout } from '@core/contexts';
import { TBranch } from '@core/types';
import { byPropertiesOf } from '@core/utils/sort';
import { Listbox, Transition } from '@headlessui/react';
import clsx from 'clsx';
import React, { Fragment } from 'react';

interface Props {
  branches: TBranch[];
}

const SelectBranch = ({ branches }: Props) => {
  const { selectedBranch, setSelectedBranch } = useLayout();

  return (
    <Listbox
      as="div"
      value={selectedBranch}
      onChange={setSelectedBranch}
      className={clsx('relative mr-2')}
    >
      {({ open }) => (
        <React.Fragment>
          <Listbox.Button
            className={clsx(
              'flex-bc border border-muted-300 rounded-md px-4 py-2 text-muted-500 gap-x-2 bg-white transition-all hover:border-primary-500',
              open
                ? 'outline-1 outline-dashed outline-offset-2 outline-muted-300'
                : ''
            )}
          >
            <span>{selectedBranch.name}</span>
            <ChevronUpIcon
              className={clsx(
                'transition-transform',
                open ? 'rotate-0' : 'rotate-180'
              )}
            />
          </Listbox.Button>

          <Transition
            as={Fragment}
            enter="transition-all duration-150"
            enterFrom="opacity-0 -translate-y-4"
            enterTo="opacity-100 translate-y-0"
            leave="transition-all duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-4"
          >
            <Listbox.Options
              className={clsx(
                'absolute right-0 -md:left-0 mt-2 max-h-60 w-[120%] overflow-auto rounded-md bg-white p-1 text-base shadow-lg border boder-muted-300'
              )}
            >
              <Listbox.Option
                key="all"
                value={{
                  id: 'all',
                  name: 'All branches',
                }}
                disabled={'all' === selectedBranch.id}
                className={clsx(
                  'p-2 transition-colors rounded mb-1 text-sm',
                  selectedBranch.id === 'all'
                    ? 'bg-primary-50 text-primary-500 pointer-events-none'
                    : 'cursor-pointer hover:bg-muted-100 text-muted-500'
                )}
              >
                All branches
              </Listbox.Option>

              {branches.sort(byPropertiesOf(['createdAt'])).map((branch) => (
                <Listbox.Option
                  key={branch.id}
                  value={{
                    id: branch.id,
                    name: branch.name,
                  }}
                  disabled={branch.id === selectedBranch.id}
                  className={clsx(
                    'p-2 transition-colors rounded [&:not(:last-child)]:mb-1 text-sm',
                    selectedBranch.id === branch.id
                      ? 'bg-primary-50 text-primary-500 pointer-events-none'
                      : 'cursor-pointer hover:bg-muted-100 text-muted-500'
                  )}
                >
                  {branch.name}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </React.Fragment>
      )}
    </Listbox>
  );
};

export default SelectBranch;
