import { InputProps } from '@core/types';
import clsx from 'clsx';
import CheckIcon from '../Icons/CheckIcon';

const CheckBox = ({
  name,
  formik,
  label,
  defaultChecked,
  className,
  ...props
}: InputProps) => {
  return (
    <div
      className={clsx('inline-flex gap-x-2 relative cursor-pointer', className)}
    >
      <div
        className={clsx(
          'cursor-pointer flex-cc flex-shrink-0 h-5 w-5 overflow-hidden relative focus-within:outline-dashed focus-within:outline-offset-2 focus-within:outline-1 focus-within:outline-muted-300'
        )}
      >
        <input
          type="checkbox"
          id={name}
          className={clsx(
            'peer absolute w-5 h-5 z-20 cursor-pointer appearance-none'
          )}
          defaultChecked={defaultChecked}
          {...formik.getFieldProps(name)}
        />
        <div
          className={clsx(
            'rounded-[.25rem] bg-white border-2 h-full w-full border-muted-400 peer-checked:border-primary-500 duration-300 outline-dashed outline-offset-2 outline-1 outline-white'
          )}
        ></div>
        <CheckIcon
          className={clsx(
            'absolute text-primary-500 transition-all transform translate-x-0 translate-y-full opacity-0 peer-checked:translate-y-0 peer-checked:opacity-100 w-[.625rem] h-auto'
          )}
        />
      </div>
      <div className={clsx('inline-flex flex-col')}>
        <label
          htmlFor={name}
          className={clsx('text-muted-400 text-sm leading-5 cursor-pointer')}
        >
          {label}
        </label>
      </div>
    </div>
  );
};

export default CheckBox;
