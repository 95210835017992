import axios from 'axios';
import { getJwt } from './jwt';

const ENV = process.env.REACT_APP_ENV;
const DEV_BASE_URL = process.env.REACT_APP_DEV_API;
const PROD_BASE_URL = process.env.REACT_APP_PROD_API;

const BASE_URL = ENV === 'dev' ? DEV_BASE_URL : PROD_BASE_URL;

export const api = axios.create({
  baseURL: BASE_URL,
});

export const authApi = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: `Bearer ${getJwt()}`,
  },
});

export const fetcher = (url: string) => api.get(url).then((res) => res.data);

export const authFetcher = (url: string) =>
  api.get(url, {
    headers: {
      Authorization: `Bearer ${getJwt()}`,
    },
  });
