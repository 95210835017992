import { EUserRole, TUser } from '@core/types';
import { arrayToObject } from '@core/utils/converter';

export const NUPEST_VERSION = '2.1.0';

export const MouStatusList = {
  NotStarted: 'not started',
  PendingDownPayment: 'pending down payment',
  InProgress: 'in progress',
  Delayed: 'delayed',
  Completed: 'completed',
  Cancelled: 'cancelled',
  InReview: 'in review',
  FullyPaid: 'fully paid',
  Reopened: 'reopened',
};

export const PERMISSIONS = {
  // General
  READ_CALENDAR: 'READ_CALENDAR',
  READ_SCHEDULE: 'READ_SCHEDULE',
  SWITCH_BRANCHES: 'SWITCH_BRANCHES',
  VIEW_MASTER_DATA: 'VIEW_MASTER_DATA',
  // Account
  HAS_BRANCH: 'HAS_BRANCH',
  EDIT_ACCOUNT_BRANCH: 'EDIT_ACCOUNT_BRANCH',
  EDIT_PASSWORD: 'EDIT_PASSWORD',
  // Branches
  READ_BRANCHES: 'READ_BRANCHES',
  CREATE_BRANCH: 'CREATE_BRANCH',
  EDIT_BRANCH: 'EDIT_BRANCH',
  DELETE_BRANCH: 'DELETE_BRANCH',
  // Treatments
  READ_TREATMENTS: 'READ_TREATMENTS',
  CREATE_TREATMENT: 'CREATE_TREATMENT',
  EDIT_TREATMENT: 'EDIT_TREATMENT',
  DELETE_TREATMENT: 'DELETE_TREATMENT',
  // Pesticides
  READ_PESTICIDES: 'READ_PESTICIDES',
  CREATE_PESTICIDE: 'CREATE_PESTICIDE',
  EDIT_PESTICIDE: 'EDIT_PESTICIDE',
  DELETE_PESTICIDE: 'DELETE_PESTICIDE',
  // Customers
  READ_CUSTOMERS: 'READ_CUSTOMERS',
  CREATE_CUSTOMER: 'CREATE_CUSTOMER',
  EDIT_CUSTOMER: 'EDIT_CUSTOMER',
  DELETE_CUSTOMER: 'DELETE_CUSTOMER',
  // Projects
  READ_PROJECTS: 'READ_PROJECTS',
  CREATE_PROJECT: 'CREATE_PROJECT',
  EDIT_PROJECT: 'EDIT_PROJECT',
  DELETE_PROJECT: 'DELETE_PROJECT',
  ASSIGN_TEAMS: 'ASSIGN_TEAMS',
  EDIT_PROJECT_BRANCH: 'EDIT_PROJECT_BRANCH',
  ADD_PROJECT_PESTICIDES: 'ADD_PROJECT_PESTICIDES',
  BULK_DELETE_TREATMENTS: 'BULK_DELETE_TREATMENTS',
  CREATE_MAPPING_EQUIPMENT: 'CREATE_MAPPING_EQUIPMENT',
  EDIT_MAPPING_EQUIPMENT: 'EDIT_MAPPING_EQUIPMENT',
  DELETE_MAPPING_EQUIPMENT: 'DELETE_MAPPING_EQUIPMENT',
  ASSIGN_TREATMENTS: 'ASSIGN_TREATMENTS',
  READ_PROJECT_SCHEDULES: 'READ_PROJECT_SCHEDULES',
  CREATE_PROJECT_SCHEDULE: 'CREATE_PROJECT_SCHEDULE',
  EDIT_PROJECT_SCHEDULE: 'EDIT_PROJECT_SCHEDULE',
  DELETE_PROJECT_SCHEDULE: 'DELETE_PROJECT_SCHEDULE',
  READ_INVOICES: 'READ_INVOICES',
  CREATE_INVOICE: 'CREATE_INVOICE',
  DELETE_INVOICE: 'DELETE_INVOICE',
  CREATE_PAYMENT_RECEIPT: 'CREATE_PAYMENT_RECEIPT',
  DELETE_PAYMENT_RECEIPT: 'DELETE_PAYMENT_RECEIPT',
  READ_COMPLAIN_VIA_WA: 'READ_COMPLAIN_VIA_WA',
  VIEW_ACTIVITY_DETAIL_MODAL: 'VIEW_ACTIVITY_DETAIL_MODAL',
  EDIT_INVOICE: 'EDIT_INVOICE',
  VIEW_ACTIVITY_IN_PROJECT: 'VIEW_ACTIVITY_IN_PROJECT',
  // Operations
  READ_OPERATIONS: 'READ_OPERATIONS',
  CHECK_IN_OPERATION: 'CHECK_IN_OPERATION',
  CREATE_OPERATION_REPORT: 'CREATE_OPERATION_REPORT',
  CHECK_OUT_OPERATION: 'CHECK_OUT_OPERATION',
  VIEW_OPERATION_REPORT: 'VIEW_OPERATION_REPORT',
  CREATE_OVERTIME: 'CREATE_OVERTIME',
  // Quality Controls
  READ_QUALITY_CONTROLS: 'READ_QUALITY_CONTROLS',
  CHECK_IN_QUALITY_CONTROL: 'CHECK_IN_QUALITY_CONTROL',
  CREATE_QUALITY_CONTROL_REPORT: 'CREATE_QUALITY_CONTROL_REPORT',
  CHECK_OUT_QUALITY_CONTROL: 'CHECK_OUT_QUALITY_CONTROL',
  VIEW_QUALITY_CONTROL_REPORT: 'VIEW_QUALITY_CONTROL_REPORT',
  // Teams
  READ_TEAMS: 'READ_TEAMS',
  CREATE_TEAM: 'CREATE_TEAM',
  EDIT_TEAM: 'EDIT_TEAM',
  RESET_TEAM_PASSWORD: 'RESET_PASSWORD',
  DELETE_TEAM: 'DELETE_TEAM',
  // Reports
  READ_REPORTS: 'READ_REPORTS',
  CREATE_REPORT: 'CREATE_REPORT',
  EDIT_REPORT: 'EDIT_REPORT',
  // Presence
  VIEW_PRESENCE: 'VIEW_PRESENCE',
  READ_PRESENCE: 'READ_PRESENCE',
  CHECK_IN_PRESENCE: 'CHECK_IN_PRESENCE',
  CHECK_OUT_PRESENCE: 'CHECK_OUT_PRESENCE',
  // Holidays
  CREATE_HOLIDAY: 'CREATE_HOLIDAY',
};

export const RULES = {
  [EUserRole.SuperAdmin]: {
    ...arrayToObject(Object.keys(PERMISSIONS), () => true),
    ...arrayToObject(
      [
        PERMISSIONS.VIEW_ACTIVITY_DETAIL_MODAL,
        PERMISSIONS.CHECK_IN_PRESENCE,
        PERMISSIONS.CHECK_OUT_PRESENCE,
        PERMISSIONS.CREATE_OVERTIME,
        PERMISSIONS.VIEW_ACTIVITY_IN_PROJECT,
      ],
      () => false
    ),
  },
  [EUserRole.Admin]: {
    ...arrayToObject(
      Object.keys(PERMISSIONS),
      (user: TUser, branchId: string) => user.userBio.branchId === branchId
    ),
    ...arrayToObject(
      [
        PERMISSIONS.CREATE_TEAM,
        PERMISSIONS.EDIT_TEAM,
        PERMISSIONS.DELETE_TEAM,
        PERMISSIONS.RESET_TEAM_PASSWORD,
      ],
      (user: TUser, role: string) => user.role !== role
    ),
    ...arrayToObject(
      [
        PERMISSIONS.READ_CALENDAR,
        // Customers
        PERMISSIONS.READ_CUSTOMERS,
        // Teams
        PERMISSIONS.READ_TEAMS,
        // Treatment
        PERMISSIONS.CREATE_TREATMENT,
        PERMISSIONS.EDIT_TREATMENT,
        // Pesticide
        PERMISSIONS.CREATE_PESTICIDE,
        PERMISSIONS.EDIT_PESTICIDE,
        // Master Data
        PERMISSIONS.VIEW_MASTER_DATA,
        PERMISSIONS.READ_PROJECTS,
        PERMISSIONS.READ_OPERATIONS,
        PERMISSIONS.READ_QUALITY_CONTROLS,
        PERMISSIONS.CREATE_PROJECT,
        PERMISSIONS.CHECK_IN_OPERATION,
        PERMISSIONS.CREATE_OPERATION_REPORT,
        PERMISSIONS.VIEW_OPERATION_REPORT,
        PERMISSIONS.CHECK_OUT_OPERATION,
        PERMISSIONS.CREATE_QUALITY_CONTROL_REPORT,
        PERMISSIONS.CHECK_IN_QUALITY_CONTROL,
        PERMISSIONS.VIEW_QUALITY_CONTROL_REPORT,
        PERMISSIONS.CHECK_OUT_QUALITY_CONTROL,
        PERMISSIONS.READ_REPORTS,
        PERMISSIONS.CREATE_REPORT,
        PERMISSIONS.EDIT_REPORT,
        PERMISSIONS.VIEW_PRESENCE,
        PERMISSIONS.HAS_BRANCH,
        PERMISSIONS.CREATE_HOLIDAY,
      ],
      () => true
    ),
    ...arrayToObject(
      [
        PERMISSIONS.SWITCH_BRANCHES,
        PERMISSIONS.VIEW_ACTIVITY_DETAIL_MODAL,
        PERMISSIONS.EDIT_PROJECT_BRANCH,
        PERMISSIONS.CHECK_IN_PRESENCE,
        PERMISSIONS.CHECK_OUT_PRESENCE,
        PERMISSIONS.EDIT_ACCOUNT_BRANCH,
        PERMISSIONS.CREATE_OVERTIME,
        PERMISSIONS.VIEW_ACTIVITY_IN_PROJECT,
      ],
      () => false
    ),
  },
  [EUserRole.Supervisor]: {
    ...arrayToObject(Object.keys(PERMISSIONS), () => true),
    ...arrayToObject(
      [
        PERMISSIONS.CREATE_BRANCH,
        PERMISSIONS.EDIT_BRANCH,
        PERMISSIONS.CREATE_TREATMENT,
        PERMISSIONS.EDIT_TREATMENT,
        PERMISSIONS.CREATE_PESTICIDE,
        PERMISSIONS.EDIT_PESTICIDE,
        PERMISSIONS.READ_CUSTOMERS,
        PERMISSIONS.CREATE_PROJECT,
        PERMISSIONS.DELETE_PROJECT,
        PERMISSIONS.READ_INVOICES,
        PERMISSIONS.SWITCH_BRANCHES,
        PERMISSIONS.EDIT_PROJECT_BRANCH,
        PERMISSIONS.ASSIGN_TEAMS,
        PERMISSIONS.READ_COMPLAIN_VIA_WA,
        PERMISSIONS.EDIT_PROJECT,
        PERMISSIONS.CHECK_IN_OPERATION,
        PERMISSIONS.CHECK_OUT_OPERATION,
        PERMISSIONS.READ_TEAMS,
        PERMISSIONS.EDIT_ACCOUNT_BRANCH,
        PERMISSIONS.CREATE_OVERTIME,
        PERMISSIONS.CREATE_HOLIDAY,
        PERMISSIONS.EDIT_TEAM,
        PERMISSIONS.EDIT_INVOICE,
        PERMISSIONS.VIEW_ACTIVITY_IN_PROJECT,
      ],
      () => false
    ),
    ...arrayToObject(
      [
        PERMISSIONS.VIEW_OPERATION_REPORT,
        PERMISSIONS.EDIT_REPORT,
        PERMISSIONS.CHECK_IN_OPERATION,
        PERMISSIONS.CHECK_OUT_OPERATION,
        PERMISSIONS.EDIT_PASSWORD,
        PERMISSIONS.EDIT_PROJECT_SCHEDULE,
        PERMISSIONS.CREATE_OPERATION_REPORT,
      ],
      (user: TUser, param: string) => user.id === param
    ),
  },
  [EUserRole.Technician]: {
    ...arrayToObject(Object.keys(PERMISSIONS), () => false),
    ...arrayToObject(
      [
        PERMISSIONS.READ_CALENDAR,
        PERMISSIONS.VIEW_ACTIVITY_DETAIL_MODAL,
        PERMISSIONS.VIEW_MASTER_DATA,
        PERMISSIONS.READ_PROJECTS,
        PERMISSIONS.READ_OPERATIONS,
        PERMISSIONS.READ_QUALITY_CONTROLS,
        PERMISSIONS.HAS_BRANCH,
        PERMISSIONS.CHECK_OUT_PRESENCE,
        PERMISSIONS.CREATE_OVERTIME,
        PERMISSIONS.VIEW_QUALITY_CONTROL_REPORT,
      ],
      () => true
    ),
    ...arrayToObject(
      [
        PERMISSIONS.CHECK_IN_OPERATION,
        PERMISSIONS.CREATE_OPERATION_REPORT,
        PERMISSIONS.VIEW_OPERATION_REPORT,
        PERMISSIONS.CHECK_OUT_OPERATION,
        PERMISSIONS.EDIT_PASSWORD,
      ],
      (user: TUser, param: string) => user.id === param
    ),
  },
  [EUserRole.Customer]: {
    ...arrayToObject(Object.keys(PERMISSIONS), () => false),
    ...arrayToObject(
      [
        PERMISSIONS.VIEW_MASTER_DATA,
        PERMISSIONS.READ_CALENDAR,
        PERMISSIONS.READ_PROJECTS,
        PERMISSIONS.READ_OPERATIONS,
        PERMISSIONS.READ_QUALITY_CONTROLS,
        PERMISSIONS.VIEW_OPERATION_REPORT,
        PERMISSIONS.VIEW_QUALITY_CONTROL_REPORT,
        PERMISSIONS.READ_COMPLAIN_VIA_WA,
        PERMISSIONS.READ_INVOICES,
        PERMISSIONS.READ_REPORTS,
      ],
      () => true
    ),
    ...arrayToObject(
      [PERMISSIONS.EDIT_TEAM],
      () => (user: TUser, param: string) => user.id === param
    ),
  },
  [EUserRole.Staff]: {
    ...arrayToObject(Object.keys(PERMISSIONS), () => false),
    ...arrayToObject(
      [
        PERMISSIONS.VIEW_PRESENCE,
        PERMISSIONS.HAS_BRANCH,
        PERMISSIONS.CHECK_IN_PRESENCE,
        PERMISSIONS.CHECK_OUT_PRESENCE,
      ],
      () => true
    ),
    ...arrayToObject(
      [PERMISSIONS.EDIT_PASSWORD],
      () => (user: TUser, param: string) => user.id === param
    ),
  },
};
