export enum EPaymentStatus {
  Unpaid = 'Unpaid',
  Paid = 'Paid',
}

export type TInvoice = {
  id: string;
  mouId: string;
  title: string;
  total: number;
  status: EPaymentStatus;
  documentUrl: string;
  createdAt: string;
  updatedAt: string;
};

export type TCreateInvoice = {
  mouId: TInvoice['mouId'];
  file: File;
  title: TInvoice['title'];
  total: TInvoice['total'];
  sendToEmail: boolean;
};
