import { DocumentationType, EMouStatus } from '@core/types';
import * as Yup from 'yup';

const IMAGE_TYPES = ['jpg', 'jpeg', 'png', 'webp'];
const FILE_TYPES = [
  'pdf',
  'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'zip',
  'rar',
  '7zip',
];

const defaultPhoto = Yup.mixed()
  .test('fileSize', 'The file is too large', (value: any) => {
    if (value) {
      const image: File = value;
      return image.size <= 100000000;
    } else {
      return true;
    }
  })
  .test('fileType', 'The file is not an image', (value: any) => {
    if (value) {
      const image: File = value;
      return IMAGE_TYPES.includes(image.type.split('/')[1]);
    } else {
      return true;
    }
  });

const defaultFile = Yup.mixed()
  .test('fileSize', 'The file is too large', (value: any) => {
    if (value) {
      const pdfFile: File = value;
      return pdfFile.size <= 100000000;
    } else {
      return true;
    }
  })
  .test('fileType', 'The file type is not supported', (value: any) => {
    if (value) {
      const pdfFile: File = value;
      return FILE_TYPES.includes(pdfFile.type.split('/')[1]);
    } else {
      return true;
    }
  });

const photoOrFile = Yup.mixed()
  .test('fileSize', 'The file is too large', (value: any) => {
    if (value) {
      const pdfFile: File = value;
      return pdfFile.size <= 100000000;
    } else {
      return true;
    }
  })
  .test('fileType', 'The file type is not supported', (value: any) => {
    if (value) {
      const pdfFile: File = value;
      return [...FILE_TYPES, ...IMAGE_TYPES].includes(
        pdfFile.type.split('/')[1]
      );
    } else {
      return true;
    }
  });

// USER VALIDATION
const email = Yup.string().email().required();
const password = Yup.string().min(6).max(80).required();

// USER-BIO VALIDATION
const fullName = Yup.string().max(60).required();
const nik = Yup.string().max(16).optional();
const dateOfBirth = Yup.string();
const phone = Yup.string().max(13).required();
const jabatan = Yup.string().max(100).optional();
const companyName = Yup.string().max(100).required();
const businessField = Yup.string().max(100).required();
const address = Yup.string().max(255).required();
const photo = defaultPhoto.required();
const legalityDocument = defaultFile.nullable();

// ASSET VALIDATION
const assetName = Yup.string().max(100).required();
const assetPhotos = Yup.array().of(photo);

// PROJECT VALIDATION
const VProject = {
  code: Yup.string().max(50).optional(),
  branchId: Yup.string().max(191).required(),
  customerId: Yup.string().max(191).required(),
  supervisorId: Yup.string().max(191).required(),
  technicians: Yup.array()
    .of(
      Yup.object({
        userId: Yup.string().required(),
      })
    )
    .optional(),
  kpi: Yup.string().max(255),
  description: Yup.string().max(255).nullable(),
  file: defaultFile,
  status: Yup.mixed<EMouStatus>().oneOf(Object.values(EMouStatus)),
  sopFile: defaultFile,
  legalFile: defaultFile,
};

const VTreatment = {
  photo: defaultPhoto,
  name: Yup.string().max(100).required(),
  description: Yup.string(),
  removePhoto: Yup.boolean(),
};

const VSchedule = {
  date: Yup.string().required(),
  activity: Yup.string().max(255).required(),
  picId: Yup.string().required(),
};

const VInvoice = {
  file: defaultFile.required(),
  name: Yup.string().max(255).required(),
  total: Yup.number().required(),
};

const VPaymentReceipt = {
  file: defaultFile.required(),
  total: Yup.number().required(),
};

const VBranch = {
  name: Yup.string().max(100).required(),
  address: Yup.string().max(255).required(),
  phone: Yup.string().max(15).required(),
  phoneName: Yup.string().max(100).required(),
  marketingPhone: Yup.string().max(15).nullable(),
  marketingName: Yup.string().max(100).nullable(),
};

const VPesticide = {
  name: Yup.string().max(255).required(),
  description: Yup.string(),
  pdfFile: defaultFile,
  xlsFile: defaultFile,
};

const VDocumentation = {
  description: Yup.string().max(255).optional(),
  constraint: Yup.string().max(255).optional(),
  recommendation: Yup.string().max(255).optional(),
  stsDocument: photoOrFile.optional(),
  documentations: Yup.array()
    .of(
      Yup.object({
        photo: photo,
        photoUrl: Yup.string().nullable().optional(),
        type: Yup.mixed<DocumentationType>()
          .oneOf(Object.values(DocumentationType))
          .required(),
        description: Yup.string().nullable(),
      })
    )
    .required(),
};

const VReport = {
  title: Yup.string().max(100).required(),
  mouId: Yup.string().max(191).nullable(),
  document: defaultFile.required(),
};

const VPassword = {
  newPassword: Yup.string().required('Please enter your password.').min(8),
  confirmPassword: Yup.string()
    .required('Please retype your password.')
    .oneOf([Yup.ref('newPassword')], 'Your passwords do not match.'),
};

const VCertificate = {
  title: Yup.string().max(255).required(),
  userId: Yup.string().max(191).required(),
  file: defaultFile.required(),
};

// ====================== SCHEMAS ========================

export const loginValidationSchema = Yup.object({
  email,
  password,
});

export const customerValidationSchema = Yup.object({
  fullName,
  dateOfBirth,
  email,
  phone,
  jabatan,
  password,
});

export const editCustomerValidationSchema = Yup.object({
  fullName,
  jabatan,
  email,
  phone,
});

export const companyValidationSchema = Yup.object({
  companyName,
  businessField,
  address,
  legalityDocument,
});

export const fileValidationSchema = Yup.object({
  photo,
});

export const assetValidationSchema = Yup.object({
  name: assetName,
  photos: assetPhotos,
});

export const projectValidationSchema = Yup.object({
  ...VProject,
});

export const treatmentValidationSchema = Yup.object({
  ...VTreatment,
});

export const scheduleValidationSchema = Yup.object({
  ...VSchedule,
});

export const bulkScheduleValidationSchema = Yup.object({
  startDate: VSchedule.date,
  endDate: VSchedule.date,
  activity: VSchedule.activity,
  picId: VSchedule.picId,
});

export const invoiceValidationSchema = Yup.object({
  ...VInvoice,
  sendToEmail: Yup.boolean().required(),
});

export const paymentReceiptValidationSchema = Yup.object({
  ...VPaymentReceipt,
  sendToEmail: Yup.boolean().required(),
});

export const createTeamValidationSchema = Yup.object({
  fullName,
  nik,
  dateOfBirth,
  email,
  phone,
  jabatan,
  password,
  address,
  branchId: VProject.branchId,
  photo: defaultPhoto.nullable(),
});

export const teamDetailsValidationSchema = Yup.object({
  fullName,
  nik,
  jabatan,
  dateOfBirth,
  address,
});

export const teamContactValidationSchema = Yup.object({
  email,
  phone,
  branchId: VProject.branchId,
});

export const assigneesValidationSchema = Yup.object({
  supervisorId: VProject.supervisorId,
});

export const operationValidationSchema = Yup.object({
  ...VDocumentation,
});

export const editOperationValidationSchema = Yup.object({
  ...VDocumentation,
  stsDocument: photoOrFile.optional(),
  documentations: Yup.array()
    .of(
      Yup.object({
        photo: defaultPhoto.nullable(),
        photoUrl: Yup.string().nullable().optional(),
        type: Yup.mixed<DocumentationType>()
          .oneOf(Object.values(DocumentationType))
          .required(),
        description: Yup.string().nullable(),
      })
    )
    .required(),
});

export const branchValidationSchema = Yup.object({
  ...VBranch,
});

export const pesticideValidationSchema = Yup.object({
  ...VPesticide,
});

export const createReportValidationSchema = Yup.object({
  ...VReport,
});

export const editReportValidationSchema = Yup.object({
  ...VReport,
  document: defaultFile,
});

export const changePasswordValidationSchema = Yup.object({
  ...VPassword,
});

export const editInvoiceValidationSchema = Yup.object({
  name: VInvoice.name,
  total: VInvoice.total,
});

export const overtimeValidationSchema = Yup.object({
  ...VSchedule,
  mouId: Yup.string().max(191).required(),
});

export const holidayValidationSchema = Yup.object({
  date: Yup.string().required(),
  title: Yup.string().max(191).required(),
});

export const certificateValidationSchema = Yup.object({
  ...VCertificate,
});

export const editCertificateValidationSchema = Yup.object({
  title: VCertificate.title,
  userId: VCertificate.userId,
  file: defaultFile.nullable().optional(),
  currentFileUrl: Yup.string(),
  deleteFileUrl: Yup.string(),
});

export const projectCodeValidationSchema = Yup.object({
  code: Yup.string().optional().nullable(),
});

export const projectPriceValidationSchema = Yup.object({
  price: Yup.number().required(),
});
