import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useAuth } from '@root/api/auth';
import Sidebar from './Sidebar';
import { useCamera, useLayout } from '@core/contexts';
import Hamburger from './Hamburger';
import Account from './Account';
import useResize from '@core/hooks/useResize';
import { Link, useNavigate } from 'react-router-dom';
import { useBranches } from '@root/api/branch';
import SelectBranch from './SelectBranch';
import { EUserRole } from '@core/types';
import FullScreenCamera from '@components/_shared/FullScreenCamera';
import MobileMenu from './MobileMenu';
import { useGeolocated } from 'react-geolocated';

interface Props {
  children: JSX.Element;
}

const MainLayout = ({ children }: Props): JSX.Element => {
  const screenSize = useResize();
  const [openSidebar, setOpenSidebar] = React.useState<boolean>(true);
  const navigate = useNavigate();
  const { CameraValue, isCameraOpen } = useCamera();

  const { logout, mutateAuth, user, isAuthLoading } = useAuth();
  const { branches, isBranchesLoading } = useBranches();
  const { pageTitle, setSelectedBranch, setCoordinates } = useLayout();

  const { coords } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
  });

  const handleLogout = async () => {
    const isLoggedOut = await logout();

    if (isLoggedOut.success) {
      await mutateAuth();
      navigate('/login', {
        replace: true,
      });
    }
  };

  useEffect(() => {
    if (coords) {
      setCoordinates([coords.latitude, coords.longitude]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coords]);

  useEffect(() => {
    if (screenSize.width !== 0 && screenSize.width < 1023) {
      setOpenSidebar(false);
    }
  }, [screenSize.width]);

  useEffect(() => {
    if (!isAuthLoading && user) {
      if (
        ![EUserRole.SuperAdmin, EUserRole.Customer].includes(user.data.role)
      ) {
        const branch = user.data.userBio.branch;

        if (branch) {
          setSelectedBranch({
            id: branch.id,
            name: branch.name,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isAuthLoading]);

  useEffect(() => {
    const successCallback = (position: GeolocationPosition) => {
      console.log(position);
    };

    const errorCallback = (error: GeolocationPositionError) => {
      console.log(error);
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    }
  }, []);

  return (
    <div className={clsx('w-full bg-muted-100 pb-20')}>
      <Sidebar {...{ openSidebar, setOpenSidebar }} />
      <MobileMenu />
      <div
        className={clsx(
          'bg-muted-100 relative min-h-screen w-full transition-all duration-300',
          openSidebar
            ? 'lg:ms-[280px] lg:max-w-[calc(100%_-_280px)]'
            : 'lg:ms-[80px] lg:max-w-[calc(100%_-_80px)]'
        )}
      >
        <div className={clsx('w-full')}>
          <div
            className={clsx(
              'sticky top-0 z-50 mb-8 h-20 -md:h-auto -md:py-3 px-4 xl:px-10 bg-white/75 backdrop-blur backdrop-filter border-b border-muted-200 -md:rounded-b-2xl -md:shadow-lg -md:shadow-black/10'
            )}
          >
            <div
              className={clsx(
                'max-w-7xl mx-auto h-full flex items-center gap-2'
              )}
            >
              <MobileBrand />
              <Hamburger {...{ openSidebar, setOpenSidebar }} />
              <h1 className="text-2xl font-light leading-normal text-muted-800 hidden md:block">
                {pageTitle}
              </h1>

              <div className="ms-auto"></div>

              {[EUserRole.SuperAdmin].includes(user.data.role) && (
                <>
                  {!isBranchesLoading ? (
                    <SelectBranch branches={branches.data} />
                  ) : (
                    <SelectBranchSkeleton />
                  )}
                </>
              )}

              {/* <Notification /> */}
              {user.data && user.data.userBio && (
                <Account
                  {...{
                    handleLogout,
                    user: user.data,
                    userBio: user.data.userBio,
                  }}
                />
              )}
            </div>
          </div>

          <div className={clsx('w-full max-w-7xl mx-auto px-4 xl:px-10')}>
            {children}
          </div>
        </div>
      </div>

      {CameraValue && (
        <FullScreenCamera isCameraOpen={isCameraOpen} {...CameraValue} />
      )}
    </div>
  );
};

const SelectBranchSkeleton = () => (
  <div className={clsx('mr-2 rounded-md animated-background w-32 h-10')} />
);

const MobileBrand = () => (
  <Link
    to="/"
    className={clsx(
      'uppercase font-bold md:hidden order-first border border-muted-300 py-0.5 px-[5px] mr-1 rounded-md flex-cc bg-white'
    )}
  >
    <span className={clsx('text-primary-500 transition-all text-4xl')}>N</span>
    <span className={clsx('text-yellow-500 text-4xl animated-dot-colors')}>
      .
    </span>
  </Link>
);

export default MainLayout;
