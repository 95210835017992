import React from 'react';

const CheckIcon = ({
  viewBox = '0 0 17 12',
  className = '',
  ...rest
}: React.SVGProps<SVGSVGElement>) => (
  <svg aria-hidden="true" viewBox={viewBox} className={className} {...rest}>
    <path
      fill="currentColor"
      d="M16.576.414a1.386 1.386 0 0 1 0 1.996l-9.404 9.176A1.461 1.461 0 0 1 6.149 12c-.37 0-.74-.139-1.023-.414L.424 6.998a1.386 1.386 0 0 1 0-1.996 1.47 1.47 0 0 1 2.046 0l3.68 3.59L14.53.414a1.47 1.47 0 0 1 2.046 0z"
    ></path>
  </svg>
);

export default CheckIcon;
