import { InputProps } from '@core/types';
import { clsx } from 'clsx';
import React from 'react';

const Input = ({ label, formik, required, disabled, ...props }: InputProps) => {
  const isError = React.useMemo(
    () => formik.touched[props.name]! && formik.errors[props.name]!,
    [props.name, formik]
  );

  return (
    <div
      className={clsx(
        'relative',
        disabled ? 'animate-pulse pointer-events-none' : '',
        props.className
      )}
    >
      {label && (
        <label
          htmlFor={props.name}
          className={clsx('block text-sm text-muted-400 mb-1')}
        >
          {label}
          {required ? (
            <span className={clsx('text-red text-base')}>
              * <span className="text-xs">(Wajib diisi)</span>
            </span>
          ) : (
            ''
          )}
        </label>
      )}
      <input
        type={props.type}
        id={props.name}
        placeholder={props.placeholder}
        className={clsx(
          'w-full h-12 text-base border text-muted-600 rounded-md px-4 py-2',
          isError ? 'border-red' : 'border-muted-300',
          props.hidden ? 'hidden' : ''
        )}
        ref={props.ref}
        autoComplete={props.autoComplete}
        {...formik.getFieldProps(props.name)}
      />
      {isError && (
        <span className={clsx('text-xs text-red')}>
          {formik.errors[props.name] as string}
        </span>
      )}
    </div>
  );
};

export default Input;
