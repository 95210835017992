import { RULES } from '@core/constants';
import { useAuth } from '@root/api/auth';
import React from 'react';

interface Props {
  permission: string;
  param: string;
  children: JSX.Element;
  prohibitedReturn?: JSX.Element;
}

const PermissionCheck = ({
  permission,
  param,
  children,
  prohibitedReturn,
}: Props) => {
  const { user } = useAuth();
  const isAccessGranted = RULES[user.data.role][permission](user.data, param);

  if (isAccessGranted) {
    return children;
  } else {
    return prohibitedReturn ?? <></>;
  }
};

export default PermissionCheck;
