import ChevronUpIcon from '@components/_shared/Icons/ChevronUpIcon';
import DiamondIcon from '@components/_shared/Icons/DiamondIcon';
import { Disclosure } from '@headlessui/react';
import clsx from 'clsx';
import React from 'react';
import { useLocation } from 'react-router-dom';

interface Props {
  setOpenSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  openSidebar: boolean;
  children: JSX.Element;
  name: string;
}

const Collapsible = ({
  setOpenSidebar,
  openSidebar,
  children,
  name,
}: Props) => {
  const location = useLocation();

  return (
    <Disclosure as="div" className={clsx('group')}>
      {({ open }) => (
        <>
          <Disclosure.Button
            onClick={() => setOpenSidebar(true)}
            className={clsx(
              'text-muted-500 hover:bg-muted-100 hover:text-muted-600 flex w-full cursor-pointer items-center rounded-lg py-3 transition-colors duration-300 custom-outline',
              openSidebar
                ? 'gap-4 px-4'
                : 'px-2 md:justify-center -md:gap-4 -md:px-4'
            )}
          >
            <DiamondIcon
              className={clsx(
                'icon w-5 h-5 shrink-0',
                location.pathname.includes('/master-data')
                  ? 'text-primary-500'
                  : ''
              )}
            />
            <span
              className={clsx(
                'block whitespace-nowrap font-sans text-sm',
                openSidebar ? '' : 'md:hidden',
                location.pathname.includes('/master-data')
                  ? 'text-primary-500'
                  : ''
              )}
            >
              {name}
            </span>
            <span
              className={clsx(
                'ms-auto items-center justify-center flex',
                openSidebar ? '' : 'md:hidden'
              )}
            >
              <ChevronUpIcon
                className={clsx(
                  'icon h-4 w-4 transition-transform duration-200',
                  open ? '' : 'rotate-180'
                )}
              />
            </span>
          </Disclosure.Button>
          <Disclosure.Panel
            as="ul"
            className={clsx(
              'border-muted-200 relative block ps-4 [&>li]:border-s-2 [&>li]:border-s-muted-300 [&>li]:ml-2 [&>li>a]:!rounded-s-none [&>li>a]:gap-2',
              open && openSidebar
                ? 'after:border-muted-200 max-h-max opacity-100'
                : 'max-h-0 overflow-hidden opacity-0 group-focus-within:max-h-max group-focus-within:overflow-visible group-focus-within:opacity-100',
              location.pathname.includes('/master-data')
                ? '[&>li.active]:border-s-primary-500 [&>li.active>a]:bg-primary-50'
                : ''
            )}
          >
            {children}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Collapsible;
