import clsx from 'clsx';
import React from 'react';

interface Props {
  openSidebar: boolean;
  setOpenSidebar(cond: boolean): void;
}

const Hamburger = ({ setOpenSidebar, openSidebar }: Props) => {
  return (
    <button
      type="button"
      className={clsx(
        'flex h-10 w-10 items-center justify-center -ms-3 transition-all hover:bg-primary-100 rounded-lg -md:order-last -md:ml-0.5 -md:hidden'
      )}
      onClick={() => setOpenSidebar(!openSidebar)}
    >
      <div className="relative h-5 w-5 scale-90">
        <span
          className={clsx(
            'bg-primary-500 absolute block h-0.5 w-full transition-all duration-100 origin-top-right',
            openSidebar
              ? 'max-w-[75%] -rotate-45 rtl:rotate-45 -top-0.5'
              : 'top-0.5'
          )}
        ></span>
        <span
          className={clsx(
            'bg-primary-500 absolute top-1/2 block h-0.5 w-full max-w-[50%] transition-all duration-300',
            openSidebar ? 'translate-x-4 opacity-0 rtl:-translate-x-4' : ''
          )}
        ></span>
        <span
          className={clsx(
            'bg-primary-500 absolute block h-0.5 w-full transition-all duration-300 bottom-0 origin-bottom-right',
            openSidebar ? 'max-w-[75%] rotate-45 rtl:-rotate-45' : ''
          )}
        ></span>
      </div>
    </button>
  );
};

export default Hamburger;
