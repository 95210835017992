import React from 'react';

const ChevronUpIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      data-v-26e5b7b0=""
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      aria-hidden="true"
      role="img"
      className={props.className ?? ''}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m18 15l-6-6l-6 6"
      ></path>
    </svg>
  );
};

export default ChevronUpIcon;
