import { useState } from 'react';

type TSelectedBranch = {
  id: string;
  name: string;
};

export interface LayoutStoreType {
  pageTitle: string;
  setPageTitle(title: string): void;
  selectedBranch: TSelectedBranch;
  setSelectedBranch(branch: TSelectedBranch): void;
  coordinates: number[];
  setCoordinates(coordinate: number[]): void;
}

const LayoutStore = (): LayoutStoreType => {
  const [pageTitle, setPageTitle] = useState<string>('');
  const [selectedBranch, setSelectedBranch] = useState<TSelectedBranch>({
    id: 'all',
    name: 'All branches',
  });
  const [coordinates, setCoordinates] = useState<number[]>([]);

  return {
    pageTitle,
    setPageTitle,
    selectedBranch,
    setSelectedBranch,
    coordinates,
    setCoordinates,
  };
};

export default LayoutStore;
