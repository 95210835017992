import { TBranch, TCertificate, TSchedule } from '.';

export enum EUserRole {
  SuperAdmin = 'SuperAdmin',
  Admin = 'Admin',
  Supervisor = 'Supervisor',
  Technician = 'Technician',
  Customer = 'Customer',
  Staff = 'Staff',
}

export type TUser = {
  id: string;
  email: string;
  role: EUserRole;
  password: string;
  resetToken: string | null;
  createdAt: Date;
  updatedAt: Date;
  // Relationships
  userBio: TUserBio;
  schedules: TSchedule[] | [];
  certificates: TCertificate[] | [];
};

export type TUserBio = {
  userId: string;
  nik: string | null;
  fullName: string;
  dateOfBirth: string;
  phone: string;
  jabatan: string | null;
  branchId: string | null;
  address: string | null;
  photoUrl: string | null;
  companyName: string | null;
  businessField: string | null;
  gmaps: string | null;
  companyLogo: string | null;
  legalityDocument: string | null;
  createdAt: Date;
  UpdatedAt: Date;
  // Relationship
  branch: TBranch;
};

export type TCreateCustomer = {
  user: {
    email: TUser['email'];
    role: TUser['role'];
    password: string;
  };
  userBio: {
    nik?: TUserBio['nik'];
    fullName: TUserBio['fullName'];
    dateOfBirth: TUserBio['dateOfBirth'];
    phone: TUserBio['phone'];
    companyName: TUserBio['companyName'];
    jabatan: TUserBio['jabatan'];
    businessField: TUserBio['businessField'];
    address: TUserBio['address'];
    gmaps: TUserBio['gmaps'];
    legalityDocument: TUserBio['legalityDocument'];
  };
};

export type TSelectedPic = {
  id: string;
  fullName: TUserBio['fullName'];
  jabatan: TUserBio['jabatan'];
  photoUrl: TUserBio['photoUrl'];
};

export type TSelectCustomer = {
  id: string;
  companyName: TUserBio['companyName'];
  businessField: TUserBio['businessField'];
  companyLogo: TUserBio['companyLogo'];
};

export type TSelectedSupervisor = {
  id: string;
  fullName: TUserBio['fullName'];
  jabatan: TUserBio['jabatan'];
  photoUrl: TUserBio['photoUrl'];
};

export type TCreateTeam = {
  user: {
    email: TUser['email'];
    role: TUser['role'];
    password: string;
  };
  userBio: {
    fullName: TUserBio['fullName'];
    nik: TUserBio['nik'];
    dateOfBirth: TUserBio['dateOfBirth'];
    phone: TUserBio['phone'];
    jabatan: TUserBio['jabatan'];
    address: TUserBio['address'];
    gmaps: TUserBio['gmaps'];
    branchId: string;
    photo: File | null;
  };
};
