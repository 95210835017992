import React, { useContext } from 'react';
import LayoutStore from './layout';
import CameraStore from './camera';

type LayoutStoreType = ReturnType<typeof LayoutStore>;
type CameraStoreType = ReturnType<typeof CameraStore>;

interface valueProps {
  layout: LayoutStoreType;
  camera: CameraStoreType;
}

const AppContext = React.createContext<valueProps | null>(null);

interface Props {
  children: React.ReactNode;
}

const AppProvider = ({ children }: Props): JSX.Element => (
  <AppContext.Provider
    value={{
      layout: LayoutStore(),
      camera: CameraStore(),
    }}
  >
    {children}
  </AppContext.Provider>
);

export default AppProvider;

export const useLayout = (): LayoutStoreType => {
  const context = useContext(AppContext);
  if (context) return context.layout;
  return {
    pageTitle: '',
    setPageTitle: () => {},
    selectedBranch: {
      id: 'all',
      name: 'ALl branches',
    },
    setSelectedBranch: () => {},
    coordinates: [],
    setCoordinates: () => {},
  };
};

export const useCamera = (): CameraStoreType => {
  const context = useContext(AppContext);
  if (context) return context.camera;
  return {
    isCameraOpen: false,
    setIsCameraOpen: () => {},
    CameraValue: null,
    Camera: () => {},
    resetCamera: () => {},
  };
};
