import { NUPEST_VERSION } from '@core/constants';
import clsx from 'clsx';
import React from 'react';

interface Props {
  openSidebar: boolean;
  className?: string;
}

const Brand = ({ openSidebar, className = '' }: Props) => (
  <h1
    className={clsx(
      'text-2xl font-bold uppercase whitespace-nowrap',
      openSidebar ? '' : 'text-center',
      className
    )}
  >
    <span
      className={clsx(
        'text-primary-500 transition-all',
        openSidebar ? '' : 'text-3xl'
      )}
    >
      N
    </span>
    <span
      className={clsx(
        'text-primary-500 transition-all',
        openSidebar ? '' : 'hidden'
      )}
    >
      UPEST{' '}
    </span>
    <span
      className={clsx(
        'text-orange-500 transition-all',
        openSidebar ? '' : 'hidden'
      )}
    >
      ID
    </span>
    <span
      className={clsx(
        'text-yellow-500 text-3xl animated-dot-colors',
        openSidebar ? '' : 'text-3xl'
      )}
    >
      .
    </span>

    {openSidebar && (
      <span className="ml-2 text-muted-400 text-sm">{NUPEST_VERSION}</span>
    )}
  </h1>
);
export default Brand;
