import clsx from 'clsx';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

interface Props {
  openSidebar: boolean;
  icon: JSX.Element;
  pathname: string;
  name: string;
  onClick: () => void;
}

const MenuItem = ({ openSidebar, icon, pathname, name, onClick }: Props) => {
  const location = useLocation();

  const checkIsActive = () => {
    if (pathname === '/') {
      return location.pathname === pathname;
    } else {
      return location.pathname.includes(pathname);
    }
  };

  return (
    <li className={clsx(checkIsActive() ? 'active' : '')}>
      <Link
        to={pathname}
        className={clsx(
          'text-muted-500 flex w-full cursor-pointer items-center rounded-lg py-3 transition-colors duration-300 custom-outline',
          checkIsActive()
            ? 'text-primary-500 bg-primary-50'
            : 'hover:bg-muted-100 hover:text-muted-600',
          openSidebar
            ? 'gap-4 px-4'
            : 'px-2 md:justify-center -md:gap-4 -md:px-4'
        )}
        onClick={() => onClick()}
      >
        {icon}
        <span
          className={clsx(
            'block whitespace-nowrap font-sans text-sm',
            openSidebar ? '' : 'md:hidden',
            checkIsActive() ? 'text-primary-500' : ''
          )}
        >
          {name}
        </span>
      </Link>
    </li>
  );
};

export default MenuItem;
