import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  children: JSX.Element | string;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  color: 'primary' | 'light' | 'orange' | 'yellow' | 'red';
  as?: 'button' | 'Link';
  to?: string;
  appearance?: 'solid' | 'outlined';
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  target?: React.HTMLAttributeAnchorTarget;
}

const MainButton = ({
  children,
  color,
  className,
  type,
  as,
  to,
  appearance,
  onClick,
  target,
}: Props) => {
  if (as === 'Link' && to) {
    return (
      <Link
        to={to}
        className={clsx(
          'main-button active:custom-outline',
          className ?? '',
          appearance === 'outlined' ? 'outlined' : '',
          color
        )}
        target={target}
      >
        {children}
      </Link>
    );
  } else {
    return (
      <button
        type={type ?? 'button'}
        className={clsx(
          'main-button active:custom-outline',
          className ?? '',
          appearance === 'outlined' ? 'outlined' : '',
          color
        )}
        onClick={onClick}
      >
        {children}
      </button>
    );
  }
};

export default MainButton;
