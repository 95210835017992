import clsx from 'clsx';
import { useFormik } from 'formik';
import { loginValidationSchema } from '@core/utils/validationSchemas';
import Input from '@components/_shared/Form/Input';
import CharaImg from '@public/images/petugas-pengendali-hama-nupest.png';
import CheckBox from '@components/_shared/Form/CheckBox';
import { useAuth } from '@root/api/auth';
import { useNavigate } from 'react-router-dom';
import showSuccessAlert from '@core/utils/alerts/showSuccessAlert';
import showErrorAlert from '@core/utils/alerts/showErrorAlert';
import { useLayout } from '@core/contexts';
import { Helmet } from 'react-helmet';
import MainButton from '@components/_shared/buttons/MainButton';
import { useState } from 'react';
import SpinnerIcon from '@components/_shared/Icons/SpinnerIcon';

const Login = () => {
  const { login, user, mutateAuth, authError, isAuthLoading } = useAuth();
  const { pageTitle, setPageTitle } = useLayout();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  setPageTitle('Login');

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: false,
    },
    validationSchema: loginValidationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      const data = await login(values);

      if (!data.success) {
        formik.setValues({
          ...formik.values,
          password: '',
        });

        showErrorAlert({
          error: data.data,
          message: data.message,
          statusCode: data.statusCode,
        });

        setIsLoading(false);
        return;
      }

      await mutateAuth();
      setIsLoading(false);

      showSuccessAlert({
        title: 'Login berhasil!',
        message: 'Anda berhasil masuk',
      });
      window.location.reload();
    },
  });

  if (user && !authError && !isAuthLoading) {
    navigate('/');
  }

  return (
    <>
      <Helmet>
        <title>
          {pageTitle ? `${pageTitle} — Nupest Indonesia` : 'Nupest Indonesia'}
        </title>
      </Helmet>
      <div className={clsx('w-full min-h-screen flex-bs')}>
        {/* Left section */}
        <div
          className={clsx(
            'relative min-h-screen flex-cc flex-col px-6 py-12 w-full lg:w-2/5'
          )}
        >
          <div className={clsx('relative mx-auto w-full max-w-sm bg-white')}>
            <div>
              <h2 className={clsx('text-3xl font-medium leading-relaxed')}>
                Welcome back.
              </h2>
              <p
                className={clsx(
                  'text-sm font-normal leading-normal mb-6 text-muted-400'
                )}
              >
                Login with your credentials
              </p>
            </div>

            <form onSubmit={formik.handleSubmit}>
              <div className={clsx('mt-5')}>
                <div>
                  <div className={clsx('space-y-4')}>
                    <Input
                      name="email"
                      label="Email address"
                      placeholder="Email address"
                      type="email"
                      formik={formik}
                    />
                    <Input
                      name="password"
                      label="Password"
                      placeholder="Password"
                      type="password"
                      formik={formik}
                    />
                  </div>
                  <div className={clsx('mt-6 flex-bc')}>
                    {/* Remember me */}
                    <CheckBox
                      name="remember"
                      formik={formik}
                      label="Trust for 365 days"
                    />
                    {/* <div className={clsx('text-xs leading-5')}>
                      <a
                        href="/auth/recover"
                        className={clsx(
                          'text-primary-600 hover:text-primary-500 font-sans font-medium underline-offset-4 transition duration-150 ease-in-out hover:underline'
                        )}
                      >
                        Forgot your password?
                      </a>
                    </div> */}
                  </div>
                  <div className={clsx('mt-6')}>
                    <MainButton
                      color="primary"
                      appearance="solid"
                      as="button"
                      type="submit"
                      className={clsx(
                        'shadow-sm w-full',
                        formik.isValid || isLoading ? '' : 'cursor-not-allowed',
                        isLoading ? 'opacity-50 pointer-events-none' : ''
                      )}
                    >
                      {isLoading ? (
                        <span>
                          <SpinnerIcon className={clsx('inline-block')} />
                          Processing...
                        </span>
                      ) : (
                        <span>Sign in</span>
                      )}
                    </MainButton>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        {/* Right section */}
        <div
          className={clsx(
            'w-3/5 -xl:hidden lg:min-h-screen flex-cc bg-muted-100'
          )}
        >
          <img
            src={CharaImg}
            alt="Login page"
            className={clsx('w-[500px] h-auto')}
          />
        </div>
      </div>
    </>
  );
};

export default Login;
