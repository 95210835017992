import React from 'react';
import clsx from 'clsx';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedRoute from '@components/ProtectedRoute';

// Pages
import Login from '@pages/login';
import ContextProvider from '@core/contexts';
import LoadingScreen from '@components/_shared/LoadingScreen';
import ScrollToTop from '@components/_shared/ScrollToTop';
import InstallPWA from '@components/_shared/InstallPWA';
// Lazy
const Dashboard = React.lazy(() => import('@pages/dashboard'));
const Customer = React.lazy(() => import('@pages/customers'));
const CustomerById = React.lazy(() => import('@pages/customers/customerById'));
const Projects = React.lazy(() => import('@pages/projects'));
const ProjectById = React.lazy(() => import('@pages/projects/projectById'));
const Treatments = React.lazy(() => import('@pages/master-data/treatments'));
const Operations = React.lazy(() => import('@pages/operations'));
const Teams = React.lazy(() => import('@pages/teams'));
const TeamById = React.lazy(() => import('@pages/teams/teamById'));
const Branches = React.lazy(() => import('@pages/master-data/branches'));
const QualityControls = React.lazy(() => import('@pages/quality-controls'));
const Pesticides = React.lazy(() => import('@pages/master-data/pesticides'));
const Reports = React.lazy(() => import('@pages/reports'));
const Presences = React.lazy(() => import('@pages/presences'));
const Account = React.lazy(() => import('@pages/account'));
const Holidays = React.lazy(() => import('@pages/master-data/holidays'));

const App = () => {
  return (
    <ContextProvider>
      <React.Suspense fallback={<LoadingScreen />}>
        <Router>
          <ScrollToTop />
          <InstallPWA />
          <main className={clsx('bg-white')}>
            <Routes>
              <Route element={<ProtectedRoute />}>
                <Route path="/" element={<Dashboard />} />
                <Route path="/customers" element={<Customer />} />
                <Route path="/customers/:id" element={<CustomerById />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/projects/:id" element={<ProjectById />} />
                <Route path="/operations" element={<Operations />} />
                <Route path="/quality-controls" element={<QualityControls />} />
                <Route path="/teams" element={<Teams />} />
                <Route path="/teams/:id" element={<TeamById />} />
                <Route path="/reports" element={<Reports />} />
                <Route
                  path="/master-data/treatments"
                  element={<Treatments />}
                />
                <Route path="/master-data/branches" element={<Branches />} />
                <Route
                  path="/master-data/pesticides"
                  element={<Pesticides />}
                />
                <Route path="/master-data/holidays" element={<Holidays />} />
                <Route path="/attendance-records" element={<Presences />} />
                <Route path={'/account'} element={<Account />} />
              </Route>
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<LoadingScreen />} />
            </Routes>
          </main>
        </Router>
      </React.Suspense>
    </ContextProvider>
  );
};

export default App;
