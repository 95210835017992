import ProfileText from '@components/ProfileText';
import CalendarCheckIcon from '@components/_shared/Icons/CalendarCheckIcon';
import CompanyIcon from '@components/_shared/Icons/CompanyIcon';
import DashboardIcon from '@components/_shared/Icons/DashboardIcon';
import DocumentIcon from '@components/_shared/Icons/DocumentIcon';
import FingerPrintIcon from '@components/_shared/Icons/FingerPrintIcon';
import MenuIcon from '@components/_shared/Icons/MenuIcon';
import OperationIcon from '@components/_shared/Icons/OperationIcon';
import PoisonIcon from '@components/_shared/Icons/PoisonIcon';
import ProjectIcon from '@components/_shared/Icons/ProjectIcon';
import TeamsIcon from '@components/_shared/Icons/TeamsIcon';
import ThreeCircles from '@components/_shared/Icons/ThreeCirclesIcon';
import VerifiedIcon from '@components/_shared/Icons/VerifiedIcon';
import WizardIcon from '@components/_shared/Icons/WizardIcon';
import PermissionCheck from '@components/_shared/PermissionCheck';
import { NUPEST_VERSION, PERMISSIONS } from '@core/constants';
import { EUserRole } from '@core/types';
import { useAuth } from '@root/api/auth';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SwipeEventData, useSwipeable } from 'react-swipeable';

const MobileMenu = () => {
  const { user } = useAuth();

  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const location = useLocation();

  const [deltaY, setDeltaY] = useState<number>(0);
  const [isSwiping, setIsSwiping] = useState<boolean>(false);

  const handleOnSwiping = (e: SwipeEventData) => {
    setIsSwiping(true);
    if (e.deltaY >= 0) {
      setDeltaY(e.deltaY);
    }
  };

  const handleSwiped = (e: SwipeEventData) => {
    setDeltaY(0);
    setIsSwiping(false);

    if (e.dir === 'Down' && e.deltaY > 100) {
      setMenuOpen(false);
    }
  };

  const swipeHandlers = useSwipeable({
    onSwiping: handleOnSwiping,
    onSwiped: handleSwiped,
  });

  useEffect(() => {
    if (menuOpen) {
      document.documentElement.style['overflowY'] = 'hidden';
    } else {
      document.documentElement.style['overflowY'] = 'auto';
    }
  }, [menuOpen]);

  return (
    <>
      <nav
        className={clsx(
          'w-full fixed z-[70] bottom-0 left-0 right-0 md:hidden bg-white shadow-[0_0_5px_5px_rgba(0,0,0,0.05)]'
        )}
      >
        <div className={clsx('w-full px-2 pb-6')}>
          <div className={clsx('w-full grid grid-cols-4 pb-1')}>
            <MenuItem
              label="Dashboard"
              path="/"
              isActive={location.pathname === '/'}
              Icon={DashboardIcon}
              setMenuOpen={setMenuOpen}
            />
            <PermissionCheck permission={PERMISSIONS.READ_CUSTOMERS} param="">
              <MenuItem
                label="Customers"
                path="/customers"
                isActive={location.pathname.includes('/customers')}
                Icon={CompanyIcon}
                setMenuOpen={setMenuOpen}
              />
            </PermissionCheck>
            <PermissionCheck permission={PERMISSIONS.READ_PROJECTS} param="">
              <MenuItem
                label="Projects"
                path="/projects"
                isActive={location.pathname.includes('/projects')}
                Icon={ProjectIcon}
                setMenuOpen={setMenuOpen}
              />
            </PermissionCheck>
            {[EUserRole.Technician, EUserRole.Customer].includes(
              user.data.role
            ) && (
              <PermissionCheck
                permission={PERMISSIONS.READ_OPERATIONS}
                param=""
              >
                <MenuItem
                  label="Operations"
                  path="/operations"
                  isActive={location.pathname.includes('/operations')}
                  Icon={OperationIcon}
                  setMenuOpen={setMenuOpen}
                />
              </PermissionCheck>
            )}
            {[EUserRole.Supervisor].includes(user.data.role) && (
              <PermissionCheck
                permission={PERMISSIONS.READ_QUALITY_CONTROLS}
                param=""
              >
                <MenuItem
                  label="Quality Controls"
                  path="/quality-controls"
                  isActive={location.pathname.includes('/quality-controls')}
                  Icon={VerifiedIcon}
                  setMenuOpen={setMenuOpen}
                />
              </PermissionCheck>
            )}
            {[EUserRole.Staff].includes(user.data.role) && (
              <>
                <PermissionCheck
                  permission={PERMISSIONS.VIEW_PRESENCE}
                  param=""
                >
                  <MenuItem
                    label="Attendance Records"
                    path="/attendance-records"
                    isActive={location.pathname.includes('/attendance-records')}
                    Icon={FingerPrintIcon}
                    setMenuOpen={setMenuOpen}
                  />
                </PermissionCheck>
                <Link
                  to="/account"
                  className={clsx('flex-cc flex-col space-y-1 w-full')}
                >
                  <div
                    className={clsx(
                      'h-6 w-6 rounded-full border border-muted-300'
                    )}
                  >
                    {user.data.userBio.photoUrl ? (
                      <img
                        src={user.data.userBio.photoUrl}
                        alt={user.data.userBio.fullName}
                        className={clsx(
                          'w-full h-full object-cover object-center rounded-full'
                        )}
                      />
                    ) : (
                      <ProfileText
                        name={user.data.userBio.fullName ?? user.data.role}
                        color="primary"
                        size="sm"
                      />
                    )}
                  </div>
                  <p
                    className={clsx(
                      'w-full text-xs font-medium text-muted-400 text-center whitespace-nowrap overflow-hidden overflow-ellipsis'
                    )}
                  >
                    Account
                  </p>
                </Link>
              </>
            )}
            <MenuButton menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
          </div>
        </div>
      </nav>

      <div
        className={clsx(
          'fixed top-0 left-0 right-0 bottom-0 w-full h-full bg-black/50 z-[60] transition-all',
          menuOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        )}
        onClick={() => setMenuOpen(false)}
      />

      <nav
        {...swipeHandlers}
        className={clsx(
          'w-full min-h-[70vh] fixed z-[70] bottom-0 left-0 right-0 flex flex-col md:hidden bg-white shadow-[0_0_5px_5px_rgba(0,0,0,0.05)] rounded-t-3xl pb-12 duration-300',
          menuOpen ? '' : 'translate-y-full',
          isSwiping ? 'transition-none' : 'transition-all'
        )}
        style={{
          transform: !menuOpen ? '' : `translateY(${deltaY}px)`,
        }}
      >
        <div
          className={clsx(
            'w-16 h-[4px] rounded-full bg-muted-200 mt-3 mb-2 mx-auto'
          )}
        />
        <div className={clsx('w-full px-3 py-4')}>
          <h2 className={clsx('text-muted-600 text-lg font-semibold mb-3')}>
            Menu utama
          </h2>
          <div className={clsx('w-full grid grid-cols-4 px-1')}>
            <MenuItem
              label="Dashboard"
              path="/"
              isActive={location.pathname === '/'}
              Icon={DashboardIcon}
              type="open"
              setMenuOpen={setMenuOpen}
            />
            <PermissionCheck permission={PERMISSIONS.READ_CUSTOMERS} param="">
              <MenuItem
                label="Customers"
                path="/customers"
                isActive={location.pathname.includes('/customers')}
                Icon={CompanyIcon}
                type="open"
                setMenuOpen={setMenuOpen}
              />
            </PermissionCheck>
            <PermissionCheck permission={PERMISSIONS.READ_PROJECTS} param="">
              <MenuItem
                label="Projects"
                path="/projects"
                isActive={location.pathname.includes('/projects')}
                Icon={ProjectIcon}
                type="open"
                setMenuOpen={setMenuOpen}
              />
            </PermissionCheck>
            <PermissionCheck permission={PERMISSIONS.READ_OPERATIONS} param="">
              <MenuItem
                label="Operations"
                path="/operations"
                isActive={location.pathname.includes('/operations')}
                Icon={OperationIcon}
                type="open"
                setMenuOpen={setMenuOpen}
              />
            </PermissionCheck>
            <PermissionCheck
              permission={PERMISSIONS.READ_QUALITY_CONTROLS}
              param=""
            >
              <MenuItem
                label="Quality Controls"
                path="/quality-controls"
                isActive={location.pathname.includes('/quality-controls')}
                Icon={VerifiedIcon}
                type="open"
                setMenuOpen={setMenuOpen}
              />
            </PermissionCheck>
            <PermissionCheck permission={PERMISSIONS.READ_REPORTS} param="">
              <MenuItem
                label="Reports"
                path="/reports"
                isActive={location.pathname.includes('/reports')}
                Icon={DocumentIcon}
                type="open"
                setMenuOpen={setMenuOpen}
              />
            </PermissionCheck>
            <PermissionCheck permission={PERMISSIONS.READ_TEAMS} param="">
              <MenuItem
                label="Teams"
                path="/teams"
                isActive={location.pathname.includes('/teams')}
                Icon={TeamsIcon}
                type="open"
                setMenuOpen={setMenuOpen}
              />
            </PermissionCheck>
            <PermissionCheck permission={PERMISSIONS.VIEW_PRESENCE} param="">
              <MenuItem
                label="Attendance Records"
                path="/attendance-records"
                isActive={location.pathname.includes('/attendance-records')}
                Icon={FingerPrintIcon}
                type="open"
                setMenuOpen={setMenuOpen}
              />
            </PermissionCheck>
          </div>

          <PermissionCheck permission={PERMISSIONS.VIEW_MASTER_DATA} param="">
            <>
              <h2
                className={clsx(
                  'text-muted-600 text-lg font-semibold mt-8 mb-3'
                )}
              >
                {[
                  EUserRole.Customer,
                  EUserRole.Technician,
                  EUserRole.Supervisor,
                ].includes(user.data.role)
                  ? 'Knowledge Base'
                  : 'Master Data'}
              </h2>
              <div className={clsx('w-full grid grid-cols-4 px-1')}>
                <MenuItem
                  label="Branches"
                  path="/master-data/branches"
                  isActive={location.pathname === '/master-data/branches'}
                  Icon={ThreeCircles}
                  type="open"
                  setMenuOpen={setMenuOpen}
                />
                <MenuItem
                  label="Treatments"
                  path="/master-data/treatments"
                  isActive={location.pathname === '/master-data/treatments'}
                  Icon={WizardIcon}
                  type="open"
                  setMenuOpen={setMenuOpen}
                />
                <MenuItem
                  label="Pesticides"
                  path="/master-data/pesticides"
                  isActive={location.pathname === '/master-data/pesticides'}
                  Icon={PoisonIcon}
                  type="open"
                  setMenuOpen={setMenuOpen}
                />
                <PermissionCheck
                  permission={PERMISSIONS.CREATE_HOLIDAY}
                  param=""
                >
                  <MenuItem
                    label="Holidays"
                    path="/master-data/holidays"
                    isActive={location.pathname === '/master-data/holidays'}
                    Icon={CalendarCheckIcon}
                    type="open"
                    setMenuOpen={setMenuOpen}
                  />
                </PermissionCheck>
              </div>
            </>
          </PermissionCheck>
        </div>
        <p className="text-center mt-auto text-xs text-muted-400">
          Nupest App v{NUPEST_VERSION}
        </p>
      </nav>
    </>
  );
};

type MenuItemProps = {
  isActive: boolean;
  path: string;
  Icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  label: string;
  type?: 'default' | 'open';
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const MenuItem = ({
  isActive,
  path,
  Icon,
  label,
  type = 'default',
  setMenuOpen,
}: MenuItemProps) => (
  <Link
    to={path}
    className={clsx(
      'w-full flex-cc flex-col space-y-1 py-3 px-2',
      type === 'open' ? 'py-0 aspect-square border-t-0 rounded-2xl' : '',
      isActive && type !== 'open'
        ? 'border-t-[3px] border-primary-500 bg-gradient-to-b from-primary-50 from-10% to-white to-90%'
        : '',
      isActive && type === 'open' ? 'bg-primary-50' : ''
    )}
    onClick={() => setMenuOpen(false)}
  >
    <Icon
      className={clsx(
        'icon w-6 h-6 shrink-0 text-muted-400',
        isActive ? 'text-primary-500' : ''
      )}
    />
    <p
      className={clsx(
        'w-full text-xs font-medium text-muted-400 text-center whitespace-nowrap overflow-hidden overflow-ellipsis',
        isActive ? 'text-primary-500 font-semibold' : ''
      )}
    >
      {label}
    </p>
  </Link>
);

type MenuButtonProps = {
  menuOpen: boolean;
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const MenuButton = ({ menuOpen, setMenuOpen }: MenuButtonProps) => (
  <button
    className={clsx('w-full py-3 flex-cc flex-col space-y-1')}
    onClick={() => setMenuOpen(!menuOpen)}
  >
    <MenuIcon className={clsx('icon w-6 h-6 shrink-0 text-muted-400')} />
    <p className={clsx('text-xs font-medium text-muted-400')}>
      {menuOpen ? 'Close' : 'Menu'}
    </p>
  </button>
);

export default MobileMenu;
