import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import MainButton from './buttons/MainButton';
import { FiDownload, FiX } from 'react-icons/fi';

const InstallPWA = () => {
  const [supportsPWA, setSupportsPWA] = useState<boolean>(false);
  const [promptInstall, setPromptInstall] = useState<any>(null);
  const [show, setShow] = useState<boolean>(true);

  useEffect(() => {
    const handler = (e: any) => {
      e.preventDefault();
      console.log('we are being triggered :D');
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener('beforeinstallprompt', handler);

    return () => window.removeEventListener('transitionend', handler);
  }, []);

  const onClick = (evt: any) => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };
  if (!supportsPWA || !show) {
    return null;
  }
  return (
    <div
      className={clsx(
        'fixed z-30 md:right-4 bottom-4 -md:bottom-[116px] md:w-[250px] w-full -md:px-4'
      )}
    >
      <div
        className={clsx(
          'p-2 bg-white rounded-md border border-muted-300 shadow-xl'
        )}
      >
        <button
          className={clsx(
            'absolute z-10 right-0 -md:right-4 -top-9 aspect-square rounded-full shadow-md border border-muted-300 text-muted-400 bg-white p-1'
          )}
          onClick={() => setShow(false)}
        >
          <FiX className="text-lg" />
        </button>
        <MainButton
          color="primary"
          appearance="solid"
          as="button"
          className={clsx('flex-cc gap-x-2 w-full')}
          onClick={onClick}
          type="button"
        >
          <>
            <FiDownload className="text-lg" />
            <span>Install Nupest App</span>
          </>
        </MainButton>
      </div>
    </div>
  );
};

export default InstallPWA;
