import { useCamera } from '@core/contexts';
import { Transition } from '@headlessui/react';
import React, { Fragment, Ref, forwardRef } from 'react';
import { FaCamera } from 'react-icons/fa';
import { FiX } from 'react-icons/fi';
import Webcam from 'react-webcam';

interface Props {
  isCameraOpen: boolean;
  capture: () => void;
  facingMode: 'user' | 'environment';
}

const FullScreenCamera = forwardRef(
  ({ isCameraOpen, capture, facingMode }: Props, ref: Ref<Webcam>) => {
    const { resetCamera } = useCamera();
    return (
      <Transition
        as={Fragment}
        show={isCameraOpen}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed top-0 left-0 right-0 bottom-0 w-screen h-screen bg-black z-[100] flex-cc flex-col">
          <Webcam
            className="w-full h-auto"
            ref={ref}
            screenshotFormat="image/jpeg"
            videoConstraints={{
              facingMode: facingMode,
              width: 1920,
              height: 1080,
            }}
            screenshotQuality={0.92}
            mirrored={true}
          />
          <button
            type="button"
            onClick={resetCamera}
            className="text-muted-400 absolute top-4 right-4 bg-white rounded-full flex-cc aspect-square shadow-md p-1"
          >
            <FiX size={24} />
          </button>
          <button
            type="button"
            className="absolute bottom-8 bg-white rounded-full p-1 pointer-events-auto"
            onClick={capture}
          >
            <div className="w-full h-full flex-cc rounded-full border border-muted-600 p-4 text-muted-400 pointer-events-none">
              <FaCamera className="pointer-events-none" size={30} />
            </div>
          </button>
        </div>
      </Transition>
    );
  }
);

export default FullScreenCamera;
