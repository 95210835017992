import ProfileText from '@components/ProfileText';
import MainButton from '@components/_shared/buttons/MainButton';
import { NUPEST_VERSION } from '@core/constants';
import { TUser, TUserBio } from '@core/types';
import { Menu, Transition } from '@headlessui/react';
import clsx from 'clsx';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  user: TUser;
  userBio: TUserBio;
  handleLogout: () => void;
}

const Account = ({ user, userBio, handleLogout }: Props) => {
  const navigate = useNavigate();

  return (
    <Menu
      as="div"
      className={clsx(
        'group inline-flex items-center justify-center text-right'
      )}
    >
      {({ open, close }) => (
        <div className={clsx('relative h-10 w-10 text-left')}>
          <Menu.Button
            type="button"
            className={clsx(
              'inline-flex h-10 w-10 items-center justify-center rounded-full ring-1 ring-transparent transition-all duration-300 border border-muted-300',
              open
                ? 'ring-primary-500 ring-offset-4'
                : 'group-hover:ring-primary-500 group-hover:ring-offset-4'
            )}
          >
            {userBio.photoUrl ? (
              <img
                src={userBio.photoUrl}
                alt={userBio.fullName}
                className={clsx(
                  'w-full h-full object-cover object-center rounded-full'
                )}
              />
            ) : (
              <ProfileText
                name={userBio.fullName ?? user.role}
                color="primary"
                size="sm"
              />
            )}
          </Menu.Button>

          <Transition
            as={React.Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className={clsx(
                'divide-muted-100 border-muted-200 absolute end-0 mt-2 w-64 origin-top-right divide-y rounded-md border bg-white shadow-lg focus:outline-none'
              )}
            >
              <div className={clsx('p-6 text-center')} role="none">
                <div
                  className={clsx(
                    'relative mx-auto h-20 w-20 flex-cc rounded-full border'
                  )}
                  role="none"
                >
                  {userBio.photoUrl ? (
                    <img
                      src={userBio.photoUrl}
                      alt={userBio.fullName}
                      className={clsx(
                        'w-full h-full object-cover object-center rounded-full'
                      )}
                    />
                  ) : (
                    <ProfileText
                      name={userBio.fullName}
                      color="primary"
                      border={true}
                    />
                  )}
                </div>
                <div className={clsx('mt-3')} role="none">
                  <h6
                    className={clsx(
                      'font-heading text-muted-800 text-sm font-medium'
                    )}
                    role="none"
                  >
                    {userBio.fullName ?? user.role}
                  </h6>
                  <p
                    className={clsx('text-muted-400 mb-4 font-sans text-xs')}
                    role="none"
                  >
                    {user.email}
                  </p>
                  <MainButton
                    color="light"
                    as="button"
                    className="w-full text-sm !rounded-xl"
                    onClick={() => {
                      navigate('/account');
                      close();
                    }}
                  >
                    Manage Account
                  </MainButton>
                </div>
              </div>

              <div className={clsx('p-6')} role="none">
                <MainButton
                  onClick={handleLogout}
                  color="red"
                  appearance="outlined"
                  className="w-full text-sm !rounded-xl"
                >
                  Logout
                </MainButton>
              </div>

              <p className="text-center mt-auto text-xs text-muted-400 py-2">
                Nupest App v{NUPEST_VERSION}
              </p>
            </Menu.Items>
          </Transition>
        </div>
      )}
    </Menu>
  );
};

export default Account;
