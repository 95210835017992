import { TUser } from './user';

export enum EPresenceStatus {
  Hadir = 'Hadir',
  Sakit = 'Sakit',
  Izin = 'Izin',
  Absen = 'Absen',
  Cuti = 'Cuti',
}

export type TPresence = {
  id: string;
  checkInTime: string;
  checkInPhoto: string;
  checkInLocation: string | null;
  checkOutTime: string | null;
  checkOutPhoto: string | null;
  checkOutLocation: string | null;
  status: EPresenceStatus;
  fileUrl: string | null;
  userId: string;
  createdAt: Date;
  updatedAt: Date;
  user: TUser;
};

export type TCreatePresence = {
  checkInTime?: TPresence['checkInTime'];
  checkInPhoto?: TPresence['checkInPhoto'];
  checkInLocation?: TPresence['checkInLocation'];
  checkOutTime?: TPresence['checkOutTime'];
  checkOutPhoto?: TPresence['checkOutPhoto'];
  checkOutLocation?: TPresence['checkOutLocation'];
  status: EPresenceStatus;
  fileUrl?: string | null;
  userId: string;
};
