import { Ref, useState } from 'react';
import Webcam from 'react-webcam';

export interface CameraStoreType {
  isCameraOpen: boolean;
  setIsCameraOpen(cond: boolean): void;
  CameraValue: CameraType | null;
  Camera(
    arg0: () => void,
    arg1: Ref<Webcam>,
    facingMode?: 'user' | 'environment'
  ): void;
  resetCamera(): void;
}

export interface CameraType {
  capture(): void;
  ref: Ref<Webcam>;
  facingMode: 'user' | 'environment';
}

const CameraStore = (): CameraStoreType => {
  const [isCameraOpen, setIsCameraOpen] = useState<boolean>(false);
  const [CameraValue, setCameraValue] = useState<CameraType | null>(null);

  const Camera = (
    capture: () => void,
    ref: Ref<Webcam>,
    facingMode: 'user' | 'environment'
  ) => {
    document.documentElement.classList.add('overflow-hidden');
    setCameraValue({ capture, ref, facingMode: facingMode ?? 'environment' });
  };

  const resetCamera = () => {
    document.documentElement.classList.remove('overflow-hidden');
    setCameraValue(null);
    setIsCameraOpen(false);
  };

  return {
    isCameraOpen,
    setIsCameraOpen,
    CameraValue,
    Camera,
    resetCamera,
  };
};

export default CameraStore;
