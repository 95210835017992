import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

const LoadingScreen = () => {
  const [progress, setProgress] = useState<number>(10);

  useEffect(() => {
    setTimeout(() => {
      setProgress(progress + 80);
    }, 100);
  }, []);

  return (
    <div className={clsx('w-screen h-screen flex-cc flex-col bg-white')}>
      <div className="mb-4">
        <img
          src="/images/nupest-logo.png"
          alt="Nupest Logo"
          className="w-40 h-auto animate-bounce"
        />
      </div>
      <div className="h-2 w-[150px] rounded-full overflow-hidden bg-muted-200">
        <div
          className="h-2 bg-[#248F47] transition-all duration-[3s]"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    </div>
  );
};

export default LoadingScreen;
