import ProfileText from '@components/ProfileText';
import ArrowLeftIcon from '@components/_shared/Icons/ArrowLeftIcon';
import CompanyIcon from '@components/_shared/Icons/CompanyIcon';
import DashboardIcon from '@components/_shared/Icons/DashboardIcon';
import { useAuth } from '@root/api/auth';
import clsx from 'clsx';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import MenuItem from './MenuItem';
import ProjectIcon from '@components/_shared/Icons/ProjectIcon';
import OperationIcon from '@components/_shared/Icons/OperationIcon';
import Collapsible from './Collapsible';
import WizardIcon from '@components/_shared/Icons/WizardIcon';
import TeamsIcon from '@components/_shared/Icons/TeamsIcon';
import ThreeCircles from '@components/_shared/Icons/ThreeCirclesIcon';
import useResize from '@core/hooks/useResize';
import VerifiedIcon from '@components/_shared/Icons/VerifiedIcon';
import PoisonIcon from '@components/_shared/Icons/PoisonIcon';
import { EUserRole } from '@core/types';
import { SwipeEventData, useSwipeable } from 'react-swipeable';
import PermissionCheck from '@components/_shared/PermissionCheck';
import { PERMISSIONS } from '@core/constants';
import DocumentIcon from '@components/_shared/Icons/DocumentIcon';
import FingerPrintIcon from '@components/_shared/Icons/FingerPrintIcon';
import Brand from './Brand';
import CalendarCheckIcon from '@components/_shared/Icons/CalendarCheckIcon';

interface Props {
  openSidebar: boolean;
  setOpenSidebar: React.Dispatch<React.SetStateAction<boolean>>;
}

const Sidebar = ({ openSidebar, setOpenSidebar }: Props) => {
  const location = useLocation();
  const { user, isAuthLoading } = useAuth();
  const [deltaX, setDeltaX] = useState<number>(0);
  const [isSwiping, setIsSwiping] = useState<boolean>(false);

  const isMobile = useResize().width < 767;

  const handleOnSwiping = (e: SwipeEventData) => {
    setIsSwiping(true);
    if (e.deltaX <= 0) {
      setDeltaX(e.deltaX);
    }
  };

  const handleSwiped = (e: SwipeEventData) => {
    setDeltaX(0);
    setIsSwiping(false);

    if (e.dir === 'Left' && e.deltaX < -100) {
      setOpenSidebar(false);
    }
  };

  const swipeHandlers = useSwipeable({
    onSwiping: handleOnSwiping,
    onSwiped: handleSwiped,
  });

  return (
    <div
      {...swipeHandlers}
      className={clsx(
        'border-muted-200 fixed start-0 top-0 z-[60] flex h-full flex-col border-r bg-white duration-300 -md:duration-500 lg:translate-x-0 overflow-x-hidden -md:hidden',
        openSidebar
          ? 'lg:w-[280px] w-full'
          : 'lg:w-[80px] -translate-x-full -md:w-full',
        isSwiping ? 'transition-none' : 'transition-all'
      )}
      style={{
        transform: !openSidebar ? '' : `translateX(${deltaX}px)`,
      }}
    >
      <div
        className={clsx(
          'flex h-16 w-full items-center justify-between pt-4',
          openSidebar ? 'px-6' : 'px-4'
        )}
      >
        <div className={clsx('w-full')}>
          <Brand openSidebar={openSidebar} />
        </div>
        <button
          type="button"
          className={clsx(
            'hover:bg-muted-200 text-muted-700 flex h-10 w-10 cursor-pointer items-center justify-center transition-colors duration-300 lg:hidden'
          )}
          onClick={() => setOpenSidebar(false)}
        >
          <ArrowLeftIcon />
        </button>
      </div>
      <div
        className={clsx(
          'relative flex w-full grow flex-col overflow-y-auto py-6 slimscroll',
          openSidebar ? 'px-6' : 'md:px-4'
        )}
      >
        <ul className={clsx('space-y-2')}>
          <MenuItem
            pathname="/"
            icon={
              <DashboardIcon
                className={clsx(
                  'icon w-5 h-5 shrink-0',
                  location.pathname === '/' ? 'text-primary-500' : ''
                )}
              />
            }
            name="Dashboard"
            openSidebar={openSidebar}
            onClick={() => (isMobile ? setOpenSidebar(false) : null)}
          />
          <PermissionCheck permission={PERMISSIONS.VIEW_MASTER_DATA} param="">
            <Collapsible
              openSidebar={openSidebar}
              setOpenSidebar={setOpenSidebar}
              name={
                !isAuthLoading &&
                [
                  EUserRole.Customer,
                  EUserRole.Technician,
                  EUserRole.Supervisor,
                ].includes(user.data.role)
                  ? 'Knowledge Base'
                  : 'Master Data'
              }
            >
              <React.Fragment>
                <MenuItem
                  pathname="/master-data/branches"
                  icon={
                    <ThreeCircles
                      className={clsx(
                        'icon w-5 h-5 shrink-0',
                        location.pathname === '/master-data/branches'
                          ? 'text-primary-500'
                          : ''
                      )}
                    />
                  }
                  name="Branches"
                  openSidebar={openSidebar}
                  onClick={() => (isMobile ? setOpenSidebar(false) : null)}
                />
                <MenuItem
                  pathname="/master-data/treatments"
                  icon={
                    <WizardIcon
                      className={clsx(
                        'icon w-5 h-5 shrink-0',
                        location.pathname === '/master-data/treatments'
                          ? 'text-primary-500'
                          : ''
                      )}
                    />
                  }
                  name="Treatments"
                  openSidebar={openSidebar}
                  onClick={() => (isMobile ? setOpenSidebar(false) : null)}
                />
                <MenuItem
                  pathname="/master-data/pesticides"
                  icon={
                    <PoisonIcon
                      className={clsx(
                        'icon w-5 h-5 shrink-0',
                        location.pathname === '/master-data/pesticides'
                          ? 'text-primary-500'
                          : ''
                      )}
                    />
                  }
                  name="Pesticides"
                  openSidebar={openSidebar}
                  onClick={() => (isMobile ? setOpenSidebar(false) : null)}
                />
                <PermissionCheck
                  permission={PERMISSIONS.CREATE_HOLIDAY}
                  param=""
                >
                  <MenuItem
                    pathname="/master-data/holidays"
                    icon={
                      <CalendarCheckIcon
                        className={clsx(
                          'icon w-5 h-5 shrink-0',
                          location.pathname === '/master-data/holidays'
                            ? 'text-primary-500'
                            : ''
                        )}
                      />
                    }
                    name="Holidays"
                    openSidebar={openSidebar}
                    onClick={() => (isMobile ? setOpenSidebar(false) : null)}
                  />
                </PermissionCheck>
              </React.Fragment>
            </Collapsible>
          </PermissionCheck>
          <PermissionCheck permission={PERMISSIONS.READ_CUSTOMERS} param="">
            <MenuItem
              pathname="/customers"
              icon={
                <CompanyIcon
                  className={clsx(
                    'icon w-5 h-5 shrink-0',
                    location.pathname.includes('/customers')
                      ? 'text-primary-500'
                      : ''
                  )}
                />
              }
              name="Customers"
              openSidebar={openSidebar}
              onClick={() => (isMobile ? setOpenSidebar(false) : null)}
            />
          </PermissionCheck>
          <PermissionCheck permission={PERMISSIONS.READ_PROJECTS} param="">
            <MenuItem
              pathname="/projects"
              icon={
                <ProjectIcon
                  className={clsx(
                    'icon w-5 h-5 shrink-0',
                    location.pathname.includes('/projects')
                      ? 'text-primary-500'
                      : ''
                  )}
                />
              }
              name="Projects"
              openSidebar={openSidebar}
              onClick={() => (isMobile ? setOpenSidebar(false) : null)}
            />
          </PermissionCheck>
          <PermissionCheck permission={PERMISSIONS.READ_OPERATIONS} param="">
            <MenuItem
              pathname="/operations"
              icon={
                <OperationIcon
                  className={clsx(
                    'icon w-5 h-5 shrink-0',
                    location.pathname === '/operations'
                      ? 'text-primary-500'
                      : ''
                  )}
                />
              }
              name="Operations"
              openSidebar={openSidebar}
              onClick={() => (isMobile ? setOpenSidebar(false) : null)}
            />
          </PermissionCheck>
          <PermissionCheck
            permission={PERMISSIONS.READ_QUALITY_CONTROLS}
            param=""
          >
            <MenuItem
              pathname="/quality-controls"
              icon={
                <VerifiedIcon
                  className={clsx(
                    'icon w-5 h-5 shrink-0',
                    location.pathname === '/quality-controls'
                      ? 'text-primary-500'
                      : ''
                  )}
                />
              }
              name="Quality Controls"
              openSidebar={openSidebar}
              onClick={() => (isMobile ? setOpenSidebar(false) : null)}
            />
          </PermissionCheck>
          <PermissionCheck permission={PERMISSIONS.READ_REPORTS} param="">
            <MenuItem
              pathname="/reports"
              icon={
                <DocumentIcon
                  className={clsx(
                    'icon w-5 h-5 shrink-0',
                    location.pathname === '/reports' ? 'text-primary-500' : ''
                  )}
                />
              }
              name="Reports"
              openSidebar={openSidebar}
              onClick={() => (isMobile ? setOpenSidebar(false) : null)}
            />
          </PermissionCheck>
          <PermissionCheck permission={PERMISSIONS.READ_TEAMS} param="">
            <MenuItem
              pathname="/teams"
              icon={
                <TeamsIcon
                  className={clsx(
                    'icon w-5 h-5 shrink-0',
                    location.pathname === '/teams' ? 'text-primary-500' : ''
                  )}
                />
              }
              name="Teams"
              openSidebar={openSidebar}
              onClick={() => (isMobile ? setOpenSidebar(false) : null)}
            />
          </PermissionCheck>
          <PermissionCheck permission={PERMISSIONS.VIEW_PRESENCE} param="">
            <MenuItem
              pathname="/attendance-records"
              icon={
                <FingerPrintIcon
                  className={clsx(
                    'icon w-5 h-5 shrink-0',
                    location.pathname === '/presences' ? 'text-primary-500' : ''
                  )}
                />
              }
              name="Attendance Records"
              openSidebar={openSidebar}
              onClick={() => (isMobile ? setOpenSidebar(false) : null)}
            />
          </PermissionCheck>
        </ul>
      </div>

      {user.data && user.data.userBio && (
        <div className={clsx('w-full', openSidebar ? 'px-4 py-2' : '')}>
          <Link
            to="/account"
            onClick={() => (isMobile ? setOpenSidebar(false) : null)}
            className={clsx(
              'flex py-3 w-full items-center gap-4 transition-all duration-150 rounded-full',
              openSidebar ? 'px-4 hover:bg-muted-100' : 'px-4'
            )}
          >
            <div
              className={clsx(
                'inline-flex items-center justify-center text-right'
              )}
            >
              <div className={clsx('relative h-10 w-10 text-left')}>
                <div
                  className={clsx(
                    'inline-flex h-10 w-10 items-center justify-center rounded-full border border-muted-300'
                  )}
                >
                  {user.data.userBio.photoUrl ? (
                    <img
                      src={user.data.userBio.photoUrl}
                      alt={user.data.userBio.fullName}
                      className={clsx(
                        'w-full h-full object-cover object-center rounded-full'
                      )}
                    />
                  ) : (
                    <ProfileText
                      name={user.data.userBio.fullName}
                      color="primary"
                    />
                  )}
                </div>
              </div>
            </div>
            <span
              className={clsx(
                'text-muted-500 dark:text-muted-500 whitespace-nowrap font-sans text-sm',
                openSidebar ? 'block' : 'hidden'
              )}
            >
              {user.data.userBio.fullName}
            </span>
          </Link>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
