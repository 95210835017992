import {
  EPaymentStatus,
  EPresenceStatus,
  TInvoice,
  TPresence,
  TSchedule,
  TUser,
} from '@core/types';
import { format, isSameDay } from 'date-fns';
import { byPropertiesOf } from './sort';

export const stringBetweenStrings = (
  startStr: string,
  endStr: string,
  str: string
) => {
  const pos = str.indexOf(startStr) + startStr.length;

  return str.substring(pos, str.indexOf(endStr, pos));
};

export const convertPhoneNumber = (phone: string) => {
  if (phone.substring(0, 2) === '08') {
    return '62'.concat(phone.slice(1));
  } else if (phone.substring(0, 1) === '+') {
    return phone.slice(1);
  } else {
    return phone;
  }
};

export const arrayToObject = (arr: string[], defaultValue: any) => {
  let obj: any = {};

  for (let i = 0; i < arr.length; i++) {
    obj[arr[i]] = defaultValue;
  }

  return obj;
};

export const dataUrlToFile = async (
  dataUrl: string,
  fileName: string
): Promise<File> => {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  return new File([blob], fileName, { type: 'image/jpeg' });
};

export const formatCurrency = (price: number) => {
  const formatter = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
  });

  return formatter.format(price);
};

export const getTotalPaid = (invoices: TInvoice[]) => {
  if (invoices && invoices.length > 0) {
    let total = 0;

    invoices.forEach((inv) => {
      if (inv.status === EPaymentStatus.Paid) {
        total += inv.total;
      }
    });

    return total;
  } else {
    return 0;
  }
};

export const pathify = (strings: string[]) => {
  return ''.concat(...strings);
};

export const convertPresenceStatus = (status: EPresenceStatus | undefined) => {
  switch (status) {
    case EPresenceStatus.Absen:
    case undefined:
      return 'Tidak Hadir';
    default:
      return status;
  }
};

export const formatScheduleForExcell = (
  allDays: Date[],
  schedules: TSchedule[]
) => {
  const columns = allDays.map((date) => format(date, 'dd MMMM yyyy'));

  let maxRow = 0;

  const schedulesByDate = allDays.map((date) => {
    const currentSchedules = schedules
      .filter((s) => isSameDay(date, new Date(s.date)))
      .map(
        (s) =>
          `${s.selectedDateInMou.mou.customer.userBio.companyName}${
            s.pic?.userBio.fullName ? ` (${s.pic?.userBio.fullName})` : ''
          }`
      );

    if (currentSchedules.length > maxRow) {
      maxRow = currentSchedules.length;
    }

    return {
      date: format(date, 'dd MMMM yyyy'),
      schedules: currentSchedules,
    };
  });

  const formatted = [];

  for (let index = 0; index < maxRow; index++) {
    const initialState: any = {};

    for (const date of columns) {
      const schedule = schedulesByDate.find((sbd) => sbd.date === date);

      if (schedule!.schedules[index]) {
        initialState[date] = schedule!.schedules[index];
      } else {
        initialState[date] = '';
      }
    }

    formatted.push(initialState);
  }

  return formatted;
};

export const formatAttendanceForExcell = (
  allDays: Date[],
  presences: TPresence[],
  teams: TUser[]
) => {
  const columns = [
    'Teams',
    ...allDays.map((date) => format(date, 'dd MMMM yyyy')),
  ];
  const names = teams
    .map((team) => team.userBio)
    .sort(byPropertiesOf(['fullName']))
    .map((team) => team.fullName);

  const formatted = [];

  for (let i = 0; i < names.length; i++) {
    let initialObject: any = {};

    for (let j = 0; j < columns.length; j++) {
      if (j === 0) {
        initialObject[columns[j]] = names[i];
      } else {
        const presence = presences.find(
          (p) =>
            p.user.userBio.fullName === names[i] &&
            isSameDay(new Date(p.checkInTime), new Date(allDays[j]))
        );

        if (presence) {
          initialObject[columns[j]] = presence.status;
        } else {
          initialObject[columns[j]] = '-';
        }
      }
    }

    formatted.push(initialObject);
  }

  return formatted;
};
