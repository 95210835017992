import { TProfileColors } from '@core/types';
import clsx from 'clsx';
import React from 'react';
import ToolTip from './ToolTip';

interface Props {
  name: string;
  size?: 'sm' | 'xs';
  color?: TProfileColors;
  alt?: string;
  border?: boolean;
}

const ProfileText = ({ name, size, color, alt, border }: Props) => {
  const getInitial = () => {
    const splittedName = name.split(' ');

    if (splittedName.length > 1 && splittedName[1] !== '') {
      return `${splittedName[0][0].toUpperCase()}${splittedName[1][0].toUpperCase()}`;
    } else {
      return `${splittedName[0][0]}`;
    }
  };

  return (
    <div
      className={clsx(
        'group profile',
        color ?? 'primary',
        border ? 'border' : ''
      )}
    >
      <span
        className={clsx(
          'block font-medium text-center uppercase pointer-events-none text-base',
          size === 'sm' ? 'text-sm' : '',
          size === 'xs' ? 'text-xs' : ''
        )}
      >
        {getInitial()}
      </span>
      {alt && <ToolTip alt={alt} />}
    </div>
  );
};

export default ProfileText;
