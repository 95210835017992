import { ERROR } from '@core/types';
import Swal from 'sweetalert2';

const showErrorAlert = (data: ERROR) => {
  Swal.fire({
    title: data.error,
    text: data.message.toString(),
    icon: 'error',
    timer: 5000,
    showConfirmButton: false,
  });
};

export default showErrorAlert;
