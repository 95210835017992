import { ERROR, TBranch, TCreateBranch, TResponse } from '@core/types';
import showErrorAlert from '@core/utils/alerts/showErrorAlert';
import { authApi, authFetcher } from '@core/utils/api';
import useSWR from 'swr';

export const useBranches = () => {
  const { data, error, isLoading, isValidating, mutate } = useSWR(
    '/branches',
    authFetcher
  );

  return {
    branches: data as TResponse<TBranch[]>,
    isBranchesLoading: isLoading,
    branchesError: error,
    isBranchesValidating: isValidating,
    mutateBranches: mutate,
  };
};

export const searchBranches = async (keyword: string) => {
  try {
    const { data: branches } = await authApi.get(`/branches?name=${keyword}`);

    return branches;
  } catch (err: any) {
    console.log(err.response.data);
    showErrorAlert(err.response.data as ERROR);
  }
};

export const createBranch = async (body: TCreateBranch) => {
  try {
    const { data: branches } = await authApi.post('/branches', body);

    return branches as TBranch;
  } catch (err: any) {
    console.log(err.response.data);
    showErrorAlert(err.response.data as ERROR);
  }
};

export const updateBranch = async (
  id: string,
  body: Partial<TCreateBranch>
) => {
  try {
    const { data: branches } = await authApi.patch(`/branches/${id}`, body);

    return branches as TBranch;
  } catch (err: any) {
    console.log(err.response.data);
    showErrorAlert(err.response.data as ERROR);
  }
};

export const deleteBranch = async (id: string) => {
  try {
    const { data: branches } = await authApi.delete(`/branches/${id}`);

    return branches as TBranch;
  } catch (err: any) {
    console.log(err.response.data);
    showErrorAlert(err.response.data as ERROR);
  }
};
